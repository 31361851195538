import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import verificationIcon from "../../../assets/images/icons/verification-icon.png";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import EarlyAccessCode from "../../../components/auth/EarlyAccessCode";
import AuthTitle from "../../../components/common/title/AuthTitle";
import Logo from "../../../components/common/logo";
import Input from "../../../components/form/Input";
import { signUpWithEmail } from "../../../api/auth";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import styles from "../auth.module.scss";

interface SignUpState {
  email: string;
  password1: string;
  password2: string;
}

const SignUp = () => {
  const hasToken = localStorage.getItem("access-token");
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const validationScheme = useCreateSchema(["email", "password1", "password2"]);
  const [loading, setLoading] = useState(false);
  const [pendingVerification, setPendingVerification] = useState(
    location.state === "verificationEmailSent" ? true : false
  );
  const [initialValues, setInitialValues] = useState<SignUpState>({
    email: "",
    password1: "",
    password2: "",
  });

  const getSubmitHandler = () => async (values: SignUpState, formik: any) => {
    setLoading(true);
    const response = await signUpWithEmail(values);

    if (response) {
      setLoading(false);
      if (response.status === 201) {
        toast.success("Verification email sent");
        setPendingVerification(true);
      } else if ((response.status = 400)) {
        toast.error("It is not possible to register user with this data");
        if (response.data) {
          Object.entries(response.data).forEach(([field, errors]) => {
            //@ts-ignore
            errors.forEach((error: string) => {
              formik.setFieldError(
                field === "non_field_errors" ? "password1" : field,
                error
              );
            });
          });
        }
      }
    }
  };

  useEffect(() => {
    if (hasToken) {
      navigate("/");
    }
  }, []);

  return (
    <Box className={styles.loginWrapper}>
      <Logo isAuth />
      {pendingVerification ? (
        <Fade>
          <Box className={styles.verificationMessage}>
            <img src={verificationIcon} alt="verification-icon" />
            <Typography
              variant="body1"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              Verification email sent. <br /> Please check your inbox for
              confirmation.
            </Typography>

            <Typography
              mt={5}
              variant="body2"
              component="h4"
              align="center"
              sx={{
                //@ts-ignore
                color: theme.palette.common.mute,
              }}
            >
              Didn't receive an email?
            </Typography>
            <Link
              to="/auth/resend-verification-email"
              style={{ color: theme.palette.primary.main }}
            >
              Resend Email
            </Link>
          </Box>
        </Fade>
      ) : (
        <Fade>
          <AuthTitle margin="35px 0 30px">Sign Up</AuthTitle>
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={validationScheme}
            enableReinitialize={true}
            onSubmit={getSubmitHandler()}
          >
            {(formik) => (
              <Form id="signup_form">
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={7}>
                    <Input name="email" formik={formik} label="Email" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      name="password1"
                      formik={formik}
                      type="password"
                      label="Password"
                      helpText="Use at least  8 characters, 1 number and 1 uppercase letter"
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Input
                      name="password2"
                      formik={formik}
                      type="password"
                      label="Repeat password"
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <EarlyAccessCode />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <PrimaryButton
                      text="Sign Up"
                      disabled={!formik.isValid}
                      loading={loading}
                      isFullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box className={styles.linkWrapper}>
                      <Typography
                        variant="body2"
                        align="left"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        Already have an account?
                      </Typography>
                      <Link
                        to="/auth/login"
                        style={{ color: theme.palette.primary.main }}
                      >
                        Login
                      </Link>
                    </Box>
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{
                        fontWeight: 300,
                        fontSize: "12px",
                        marginTop: "30px",
                        textAlign: "justify",
                        //@ts-ignore
                        color: theme.palette.common.whiteToGreen,
                      }}
                    >
                      By signing up I confirm that I have read, understood and
                      accept the terms of use, and consent to the processing of
                      my personal data as started in the privacy policy
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Fade>
      )}
    </Box>
  );
};

export default SignUp;
