import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Typography, useTheme } from "@mui/material";
import Guage from "../../../guage";
import NewsCard from "../../../common/newsCard";
import Progressbar from "../../../progressbar";
import Loading from "../../../common/loading";
import CardTitle from "../../../common/title/CardTitle";

interface CryptoSignalTabProps {
  fetchNextPage: any;
  guageValue: number;
  cryptoSignalData: {
    count: number;
    next: string | null;
    results: {
      signal: {};
      sentiment: {
        0: number;
        1: number;
        2: number;
      };
      relevant_tweets: [];
    };
  };
}

interface CryptoSignalItem {
  id: number;
  user_id_str: string;
  image?: string;
  screen_name: string;
  created_at: string;
  full_text: string;
  media: string;
  reply_count: string;
  favorite_count: string;
  retweet_count: string;
}

const CryptoSignalTab: React.FC<CryptoSignalTabProps> = ({
  guageValue,
  cryptoSignalData,
  fetchNextPage,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
      mt={3}
    >
      <CardTitle
        isCenter
        hasInfo
        infoTitle="Signal"
        info="The signal model depicts the coin's buy interest based on influencers and economic news. Higher percentages signify more talk of buying in influencer discussions and bullish market/economic indicator impact in recent news, potentially boosting coin prices."
        small
      >
        Signal
      </CardTitle>
      <Box
        sx={{
          marginTop: "-1rem",
          marginBottom: "1.6rem",
        }}
      >
        <Guage
          value={guageValue}
          // isEmpty={isValidObject(newsData.results.signal) ? false : true}
        />
      </Box>
      <CardTitle
        isCenter
        hasInfo
        infoTitle="Sentiment"
        info="Visualizes the degrees of positivity, negativity, and neutrality within the dataset. Coupled with the signal model, it provides users insights into the overall sentiment people hold toward the particular coin and the corresponding news."
        small
      >
        Sentiment
      </CardTitle>
      {cryptoSignalData.results && (
        <Box sx={{ marginTop: "-.7rem" }}>
          <Progressbar
            negative={cryptoSignalData.results.sentiment[0] ?? 0}
            neutral={cryptoSignalData.results.sentiment[1] ?? 0}
            positive={cryptoSignalData.results.sentiment[2] ?? 0}
          />
        </Box>
      )}

      {cryptoSignalData.results &&
      cryptoSignalData.results.relevant_tweets.length > 0 ? (
        <Box
          className="default-card-style"
          //@ts-ignore
          sx={{ background: theme.palette.common.card }}
        >
          <InfiniteScroll
            dataLength={cryptoSignalData.results.relevant_tweets.length}
            next={fetchNextPage}
            hasMore={cryptoSignalData.next ? true : false}
            loader={<Loading />}
          >
            {cryptoSignalData.results.relevant_tweets.map(
              (cryptoSignal: CryptoSignalItem, idx: number) => (
                <NewsCard
                  key={idx}
                  screen_name={cryptoSignal.screen_name}
                  id={cryptoSignal.user_id_str}
                  name={cryptoSignal.screen_name}
                  media={cryptoSignal.media}
                  date={`${moment
                    .parseZone(cryptoSignal.created_at)
                    .format("DD MMMM YYYY")}`}
                  content={cryptoSignal.full_text}
                  reply_count={
                    cryptoSignal.reply_count ? cryptoSignal.reply_count : 0
                  }
                  favorite_count={
                    cryptoSignal.favorite_count
                      ? cryptoSignal.favorite_count
                      : 0
                  }
                  retweet_count={
                    cryptoSignal.retweet_count ? cryptoSignal.retweet_count : 0
                  }
                />
              )
            )}
          </InfiniteScroll>
        </Box>
      ) : null}

      {cryptoSignalData.results.relevant_tweets.length < 1 &&
      Object.keys(cryptoSignalData.results.signal).length < 2 &&
      Object.keys(cryptoSignalData.results.sentiment).length < 2 ? (
        <Box //@ts-ignore
          sx={{ background: theme.palette.common.card }}
          p={3}
          className="default-card-style"
        >
          <Typography align="center">Couldn't find any data</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default CryptoSignalTab;
