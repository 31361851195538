import { useState } from "react";
import { toast } from "react-hot-toast";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import Input from "../../form/Input";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import { changePassword } from "../../../api/auth";

interface PasswordState {
  new_password1: string;
  new_password2: string;
  old_password: string;
}

const PasswordChange = () => {
  const [loading, setLoading] = useState(false);
  const [passwordChange, setPasswordChange] = useState<PasswordState>({
    new_password1: "",
    new_password2: "",
    old_password: "",
  });

  const passwordUpdateHandler =
    () => async (values: PasswordState, formik: any) => {
      setLoading(true);
      changePassword(values)
        .then((response) => {
          setPasswordChange({ ...passwordChange });
          toast.success("Password updated");
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Can't update password");
          if (err.response.data) {
            Object.entries(err.response.data).forEach(([field, errors]) => {
              //@ts-ignore
              errors.forEach((error: []) => {
                formik.setFieldError(field, error);
              });
            });
          }
          setLoading(false);
        });
    };

  return (
    <Formik
      initialValues={passwordChange}
      validateOnMount={true}
      enableReinitialize={true}
      validationSchema={useCreateSchema([
        "old_password",
        "new_password1",
        "new_password2",
      ])}
      onSubmit={passwordUpdateHandler()}
    >
      {(formik) => (
        <Form id="password_form">
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12}>
              <Input
                name="old_password"
                formik={formik}
                label="Old password:"
                hasInlineLabel={true}
                hasInnerLabel={false}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="new_password1"
                formik={formik}
                label="New password:"
                hasInlineLabel={true}
                hasInnerLabel={false}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="new_password2"
                formik={formik}
                label="Repeat new password:"
                hasInlineLabel={true}
                hasInnerLabel={false}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                text="Submit"
                disabled={formik.isValid ? false : true}
                isFullWidth
                loading={loading}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordChange;
