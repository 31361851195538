import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import appSlice from "./app/appSlice";
import accountSlice from "./auth/accountSlice";
import cryptoSlice from "./crypto/cryptoSlice";
import moversSlice from "./crypto/moversSlice";
import influencersSlice from "./influencers/influencersSlice";
import cryptosSlice from "./crypto/cryptosSlice";
import signalSlice from "./signal/signalSlice";
import newsSlice from "./news/newsSlice";
import cryptoIndicatorsSlice from "./crypto/cryptoIndicatorsSlice";
import walletSlice from "./wallet/walletSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    account: accountSlice,

    cryptos: cryptosSlice,
    crypto: cryptoSlice,
    cryptoIndicators: cryptoIndicatorsSlice,

    movers: moversSlice,
    signal: signalSlice,
    influencers: influencersSlice,
    news: newsSlice,
    wallet: walletSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
