import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-hot-toast";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { TbChartCandle, TbChartLine } from "react-icons/tb";
import DashboardLayout from "../../layout/dashboardLayout";
import CryptoHeader from "../../components/crypto/cryptoHeader";
import CryptoInfo from "../../components/crypto/cryptoInfo";
import CryptoChart from "../../components/crypto/cryptoChart";
import Loading from "../../components/common/loading";
import FinancialChart from "../../components/crypto/financialChart";
import CryptoSignal from "../../components/crypto/cryptoSignal";
import CardTitle from "../../components/common/title/CardTitle";
import { calculateDate, calculateUnixTime } from "../../utils/functions";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  fetchSingleAsset,
  fetchSingleAssetHistory,
} from "../../features/crypto/cryptoSlice";
import {
  fetchPriceActionPattern,
  fetchSupportAndResistance,
} from "../../features/crypto/cryptoIndicatorsSlice";

interface itemType {
  id: string;
}

const Crypto = () => {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const dispatch: AppDispatch = useAppDispatch();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const cryptoIndicatorsState: any = useSelector(
    (state: RootState) => state.cryptoIndicators
  );

  const [cryptoFilter, setCryptoFilter] = useState({
    type: "1H",
    interval: "m1",
  });

  const [chartType, setChartType] = useState("financial");
  const [technichalMethod, setTechnichalMethod] = useState<null | string>(null);
  const [showTechnichalMethod, setShowTechnichalMethod] = useState(false);
  const [assetAvailability, setAssetAvailability] = useState(false);
  const [foundAsset, setFoundAsset] = useState(false);
  const [cryptoChartData, setCryptoChartData] = useState([]);
  const [loadingHistoricalChart, setLoadingHistoricalChart] = useState(false);

  const handleAlignment = (newAlignment: string) => {
    setChartType(newAlignment);
  };

  const cryptoFilterChangeHandler = (type: string, interval: string) => {
    setCryptoFilter({
      type: type,
      interval: interval,
    });
  };

  const technichalMethodHandler = (method: string) => {
    if (cryptoFilter.type !== "1H" && cryptoFilter.type !== "1D") {
      setTechnichalMethod(method);
    } else {
      toast.error("This indicator is not available for this time frame");
    }

    if (technichalMethod === method) {
      setShowTechnichalMethod(!showTechnichalMethod);
    } else {
      setShowTechnichalMethod(true);
    }
  };

  const fetchCoinData = async () => {
    const response = await dispatch(fetchSingleAsset(params.crypto));
    return response;
  };

  const fetchCoinHistoryData = async () => {
    const requestData = {
      name: params.crypto,
      interval: cryptoFilter.interval,
      start: calculateUnixTime(cryptoFilter.type)?.start,
      end: calculateUnixTime(cryptoFilter.type)?.end,
    };
    const response = await dispatch(fetchSingleAssetHistory(requestData));
    return response;
  };

  useEffect(() => {
    if (cryptosState.availableCoins) {
      fetchCoinData()
        .then((response) => {
          if (response.payload.data) {
            setFoundAsset(true);
          } else {
            setFoundAsset(false);
          }
        })
        .catch((err) => {});
    }
  }, [cryptosState.availableCoins]);

  useEffect(() => {
    if (cryptosState.availableCoins) {
      setLoadingHistoricalChart(true);
      fetchCoinHistoryData()
        .then((response) => {
          setAssetAvailability(true);
          setCryptoChartData(
            response.payload.data.map(
              (chartItem: { date: string; priceUsd: number }) => ({
                x:
                  cryptoFilter.interval === "m1" ||
                  cryptoFilter.interval === "h1"
                    ? moment(chartItem.date).format("HH:mm")
                    : moment(chartItem.date).format("YYYY/MM/DD"),
                y: Number(chartItem.priceUsd),
              })
            )
          );
          setLoadingHistoricalChart(false);
        })
        .catch((err) => {
          setAssetAvailability(false);
        });
    }
  }, [cryptoFilter, cryptosState.availableCoins]);

  useEffect(() => {
    if (cryptosState.availableCoins && showTechnichalMethod) {
      if (
        cryptoFilter.type !== "1H" &&
        cryptoFilter.type !== "1D" &&
        technichalMethod
      ) {
        const requestData = {
          coin: cryptosState.availableCoins.find(
            (item: itemType) => item.id === params.crypto
          ).logo,
          start_date: calculateDate(cryptoFilter.type),
          end_date: moment().format("YYYY-MM-DD"),
        };

        if (technichalMethod === "sr")
          dispatch(fetchSupportAndResistance(requestData));

        if (technichalMethod === "pap")
          dispatch(fetchPriceActionPattern(requestData));
      }
    }
  }, [cryptoFilter, cryptosState.availableCoins, technichalMethod]);

  return (
    <DashboardLayout
      title={
        params.crypto
          ? `Coinfident | ${
              params.crypto[0].toUpperCase() + params.crypto.slice(1)
            }`
          : "Coinfident"
      }
    >
      <Box mt={2}>
        {cryptoState.coinLoading || !cryptosState.availableCoins ? (
          <Loading />
        ) : cryptoState.coin && foundAsset ? (
          <Box mt={5}>
            <CryptoHeader
              data={cryptoState.coin}
              historicalData={cryptoState.coinHistory}
              coinId={
                location.state
                  ? location.state.coin_id
                  : cryptosState.availableCoins.find(
                      (item: itemType) => item.id === params.crypto
                    )
                  ? cryptosState.availableCoins.find(
                      (item: itemType) => item.id === params.crypto
                    ).coin_id
                  : 3
              }
            />
            <Box>
              <ToggleButtonGroup
                value={chartType}
                exclusive
                aria-label="chart type"
                size="small"
                sx={{
                  background:
                    //@ts-ignore
                    theme.palette.grey.main,
                  borderRadius: "10px 10px 0 0",
                  minWidth: "auto !important",
                  overflow: "hidden",
                }}
              >
                <ToggleButton
                  value="financial"
                  aria-label="financial"
                  onClick={() => handleAlignment("financial")}
                  sx={{
                    padding: "7px 15px",
                    borderRadius: 0,
                  }}
                >
                  <TbChartCandle size={22} />
                </ToggleButton>
                <ToggleButton
                  value="crypto"
                  aria-label="crypto"
                  onClick={() => handleAlignment("crypto")}
                  sx={{
                    padding: "7px 15px",
                    borderRadius: 0,
                  }}
                >
                  <TbChartLine size={22} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              sx={{
                minHeight: "200px",
                border: `1px solid ${
                  //@ts-ignore
                  theme.palette.grey.main
                }`,
                borderRadius: "0 10px 10px 10px",
                overflow: "hidden",
              }}
            >
              {chartType === "crypto" && assetAvailability ? (
                <CryptoChart
                  name="Price"
                  data={cryptoChartData}
                  cryptoFilter={cryptoFilter}
                  cryptoIndicators={cryptoIndicatorsState}
                  technichalMethod={technichalMethod}
                  showTechnichalMethod={showTechnichalMethod}
                  technichalMethodHandler={technichalMethodHandler}
                  cryptoFilterChangeHandler={cryptoFilterChangeHandler}
                  loadingHistoricalChart={loadingHistoricalChart}
                  isAvailable={cryptosState.availableCoins.find(
                    (item: itemType) => item.id === params.crypto
                  )}
                />
              ) : (
                <FinancialChart symbol={cryptoState.coin.data.symbol} />
              )}
            </Box>
            <CryptoInfo data={cryptoState.coin} />

            <Box mt={3}>
              <CardTitle isCenter>Social Prediction</CardTitle>
            </Box>
            <CryptoSignal
              isAvailable={cryptosState.availableCoins.find(
                (item: itemType) => item.id === params.crypto
              )}
              coinId={
                location.state
                  ? location.state.coin_id
                  : cryptosState.availableCoins.find(
                      (item: itemType) => item.id === params.crypto
                    )
                  ? cryptosState.availableCoins.find(
                      (item: itemType) => item.id === params.crypto
                    ).coin_id
                  : 3
              }
            />
          </Box>
        ) : (
          <Typography
            align="center"
            variant="body1"
            component="h6"
            mt={10}
            color={theme.palette.text.primary}
          >
            Crypto data is not available
          </Typography>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default Crypto;
