import { useState, useRef } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { IoFilter } from "react-icons/io5";
import Loading from "../../common/loading";
import MuiPagination from "../../common/pagination";
import CardTitle from "../../common/title/CardTitle";
import FullPageBlur from "../../common/fullPageBlur";
import InfluencerCard from "../../influencers/influencerCard";
import InfluencersFilter from "../../influencers/influencersFilter";

interface InfluencerDataType {
  id_str: number;
  header: string;
  logo: string;
  name: string;
  screen_name: string;
}

interface AllInfluencersProps {
  isFilterOpen: boolean;
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  filterInfluencers: Function;
  filterHandler: any;
  page: number;
  influencersPaginationHandler: any;
  influencersList: {
    pinnedInfluencersLoading: boolean;
    influencersListLoading: boolean;
    pinnedInfluencers: {
      results: [];
    };
    influencersList: {
      results: [];
      count: number;
    };
  };
  styles: any;
}

const AllInfluencers: React.FC<AllInfluencersProps> = ({
  influencersList,
  styles,
  isFilterOpen,
  setIsFilterOpen,
  selectedFilter,
  setSelectedFilter,
  filterInfluencers,
  filterHandler,
  page,
  influencersPaginationHandler,
}) => {
  const theme = useTheme();
  const clickedBtnRef = useRef();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [clickedInfluencerData, setClickedInfluencerData] = useState<null | {
    id_str: string | number;
    screen_name: string;
  }>(null);

  return (
    <>
      {isFilterOpen && (
        <InfluencersFilter
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterInfluencers={filterInfluencers}
        />
      )}
      <FullPageBlur
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        closeFullBlurFunction={() => setClickedInfluencerData(null)}
      />
      <FullPageBlur isVisible={isFilterOpen} setIsVisible={setIsFilterOpen} />
      <Box mt={3} className={styles.influencersPage} ref={clickedBtnRef}>
        <Box>
          <Box sx={{ position: "relative" }}>
            <CardTitle isCenter={false}>Influencers</CardTitle>
            <IconButton
              aria-label="filter"
              size="small"
              className={styles.influencersFilter}
              onClick={filterHandler}
            >
              <IoFilter
                size={22}
                //@ts-ignore
                color={theme.palette.common.whiteToGreen}
              />
            </IconButton>

            {influencersList.influencersListLoading ||
            influencersList.pinnedInfluencersLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  margin: "1rem auto",
                }}
              >
                <Loading />
              </Box>
            ) : (
              <Box className={styles.influencersWrapper}>
                {influencersList.influencersList?.results &&
                  influencersList.influencersList?.results.map(
                    (inf: InfluencerDataType) => (
                      <InfluencerCard
                        key={inf.id_str}
                        influencerData={inf}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        clickedInfluencerData={clickedInfluencerData}
                        setClickedInfluencerData={setClickedInfluencerData}
                        isPinned={
                          influencersList.pinnedInfluencers?.results.findIndex(
                            (item: { id_str: number | string }) =>
                              item.id_str === inf.id_str
                          ) > -1
                            ? true
                            : false
                        }
                      />
                    )
                  )}
              </Box>
            )}
          </Box>
          {!influencersList.influencersListLoading &&
          !influencersList.pinnedInfluencersLoading ? (
            <MuiPagination
              page={page}
              count={Math.ceil(influencersList.influencersList?.count / 100)}
              handleChange={influencersPaginationHandler}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default AllInfluencers;
