import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import FullPageBlur from "../fullPageBlur";
import BottomDrawer from "../bottomDrawer";
import Avatar from "../avatar";
import TableTitle from "../title/TableTitle";
import StatisticsGuage from "../../guage/StatisticsGuage";
import SmallAreaChart from "../../charts/SmallAreaChart";
import {
  calculateDayPercentChange,
  calculateGuageValue,
  calculateWeekPercentChange,
  formatNumber,
  formatPrice,
  getCoinImage,
  sortTableData,
} from "../../../utils/functions";
import styles from "./assetsTable.module.scss";

interface infType {
  user_id_str: string;
  screen_name: string;
}

interface TableHeaderType {
  title: string;
  isSticky: boolean;
  info: null | string;
}

interface itemInfoProps {
  infoTitle: string;
  info: string;
}

const tabelHeaderData = [
  { title: "Assets", hiddenFor: [], isSticky: true, info: null },
  { title: "Price", hiddenFor: ["shareOfVoice"], isSticky: false, info: null },
  {
    title: "Buy score (86%)",
    hiddenFor: ["marketcapWeighted"],
    isSticky: false,
    info: "The signal model depicts the coin's buy interest based on influencers and economic news. Higher percentages signify more talk of buying in influencer discussions and bullish market/economic indicator impact in recent news, potentially boosting coin prices.",
  },
  {
    title: "24h %",
    hiddenFor: ["shareOfVoice", "marketcapWeighted"],
    isSticky: false,
    info: null,
  },
  {
    title: "Top Influencers",
    hiddenFor: ["shareOfVoice", "marketcapWeighted"],
    isSticky: false,
    info: "Collection of posts and updates from influential figures within the cryptocurrency community.",
  },
  {
    title: "Posts",
    hiddenFor: ["marketcapWeighted"],
    isSticky: false,
    info: null,
  },
  {
    title: "Posts change rate",
    hiddenFor: ["marketcapWeighted"],
    isSticky: false,
    info: "The percentage of changes in the number of posts compared to the last seven days",
  },
  {
    title: "Market Cap",
    hiddenFor: ["shareOfVoice"],
    isSticky: false,
    info: null,
  },
  {
    title: "24h Volume",
    hiddenFor: ["shareOfVoice"],
    isSticky: false,
    info: null,
  },
  {
    title: "Last 7 days price",
    hiddenFor: ["shareOfVoice", "marketcapWeighted"],
    isSticky: false,
    info: null,
  },
];

interface AssetsTableProps {
  data: any;
  loading?: boolean;
  type: string;
  selectedCoins?: any;
  setSelectedCoins?: any;
}

const AssetsTable: React.FC<AssetsTableProps> = ({
  data,
  type,
  loading,
  selectedCoins,
  setSelectedCoins,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [itemInfo, setItemInfo] = useState({
    infoTitle: "",
    info: "",
  });
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue =
      sortTableData(sortColumn) === "signal"
        ? calculateGuageValue(
            a.signal[0] ?? 0,
            a.signal[1] ?? 0,
            a.signal.null ?? 0
          )
        : sortTableData(sortColumn) === "last_tweet_counts"
        ? a.last_tweet_counts / a.tweet_count
        : sortTableData(sortColumn) === "tweet_count"
        ? Number(a.tweet_count ? a.tweet_count : 0)
        : sortTableData(sortColumn) === "latest_price"
        ? Number(a.latest_price)
        : sortTableData(sortColumn) === "24h %"
        ? Math.abs(calculateDayPercentChange(a.prices))
        : sortTableData(sortColumn) === "last 7 days price"
        ? calculateWeekPercentChange(a.prices, a.latest_price)
        : a[sortTableData(sortColumn)];

    const bValue =
      sortTableData(sortColumn) === "signal"
        ? calculateGuageValue(
            b.signal[0] ?? 0,
            b.signal[1] ?? 0,
            b.signal.null ?? 0
          )
        : sortTableData(sortColumn) === "last_tweet_counts"
        ? b.last_tweet_counts / b.tweet_count
        : sortTableData(sortColumn) === "tweet_count"
        ? Number(b.tweet_count ? b.tweet_count : 0)
        : sortTableData(sortColumn) === "latest_price"
        ? Number(b.latest_price)
        : sortTableData(sortColumn) === "24h %"
        ? Math.abs(calculateDayPercentChange(b.prices))
        : sortTableData(sortColumn) === "last 7 days price"
        ? calculateWeekPercentChange(b.prices, b.latest_price)
        : b[sortTableData(sortColumn)];

    if (typeof aValue === "number" && typeof bValue === "number") {
      return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
    } else {
      return sortDirection === "asc"
        ? (aValue ?? "").localeCompare(bValue ?? "")
        : (bValue ?? "").localeCompare(aValue ?? "");
    }
  });

  const showCellInfo = (itemInfo: itemInfoProps) => {
    return (
      <>
        <FullPageBlur isVisible={open} setIsVisible={setOpen} />
        <BottomDrawer open={open} setOpen={setOpen} isSolid>
          <Typography variant="h6" align="center" mb={1}>
            {itemInfo.infoTitle}
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: "initial" }}>
            {itemInfo.info}
          </Typography>
        </BottomDrawer>
      </>
    );
  };

  function selectCoinHandler(event: React.ChangeEvent<any>, coinId: number) {
    event.stopPropagation();
    const isChecked = event.target.checked;

    if (!isChecked) {
      setSelectedCoins(
        selectedCoins.filter((selectedItem: {}) => selectedItem !== coinId)
      );
    } else {
      if (selectedCoins.length >= 3) {
        toast.remove();
        toast.error("You can only choose three items at a time");
      } else {
        setSelectedCoins([...selectedCoins, coinId]);
      }
    }
  }

  return (
    <Box className={styles.assetsWrapper}>
      <TableContainer
        sx={{
          maxHeight: 400,
          background: theme.palette.background.default,
          borderRadius: "26px",
          marginTop: "1rem",
        }}
      >
        <Table
          stickyHeader
          aria-label="Coins table"
          sx={{
            maxHeight: 1200,
            background: theme.palette.background.default,
            borderRadius: "26px",
            marginTop: "1rem",
          }}
        >
          <TableHead
            sx={{
              //@ts-ignore
              background: theme.palette.common.backdrop,
              zIndex: 100,
              position: "sticky",
            }}
          >
            <TableRow>
              {tabelHeaderData
                .filter((item: any) => !item.hiddenFor.includes(type))
                .map((tableItem: TableHeaderType) => (
                  <TableCell
                    onClick={() => handleSort(tableItem.title.toLowerCase())}
                    key={tableItem.title}
                    align="left"
                    sx={{
                      position: "sticky",
                      left: tableItem.isSticky ? -1 : "auto",
                      top: -1,
                      cursor: "pointer",
                      zIndex: tableItem.isSticky ? 100 : 10,
                      background: theme.palette.background.default,
                      boxShadow:
                        tableItem.title === "Assets"
                          ? "inset -1px 0px 1px 0px rgba(255, 255, 255, .1)"
                          : "none",
                    }}
                  >
                    <Box
                      sx={{
                        whiteSpace: "nowrap",
                        textAlign: tableItem.isSticky ? "left" : "center",
                        fontWeight: 500,
                        fontSize: ".9rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent:
                          tableItem.title === "Assets"
                            ? "flex-start"
                            : "center",
                      }}
                      color={theme.palette.text.primary}
                    >
                      <TableTitle
                        hasInfo={tableItem.info ? true : false}
                        info={tableItem.info ? tableItem.info : ""}
                        infoTitle={tableItem.title}
                        open={open}
                        setOpen={setOpen}
                        setItemInfo={setItemInfo}
                      >
                        {tableItem.title}
                      </TableTitle>

                      {sortColumn === tableItem.title.toLowerCase() &&
                        (sortDirection === "asc" ? (
                          <BiChevronUp
                            size={17}
                            style={{ marginLeft: ".2rem" }}
                          />
                        ) : (
                          <BiChevronDown
                            size={17}
                            style={{ marginLeft: ".2rem" }}
                          />
                        ))}
                    </Box>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              sortedData.map((coin: any) => (
                <TableRow
                  key={coin.id}
                  onClick={() => {
                    navigate(`/prices/${coin.full_id}/`, {
                      state: {
                        coin_id: coin.coin_id,
                      },
                    });
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    className={styles.coinInfoWrapper}
                    sx={{
                      zIndex: 10,
                      padding: 0,
                      paddingRight: "10px",
                      height: "100%",
                      minHeight: "100px",
                      position: "sticky",
                      left: -1,
                      top: 0,
                      background: theme.palette.background.default,
                      boxShadow:
                        "inset -1px 0px 1px 0px rgba(255, 255, 255, .1)",
                    }}
                    component="th"
                    align="left"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: "80px",
                      }}
                    >
                      {(type === "shareOfVoice" ||
                        type === "marketcapWeighted") && (
                        <Checkbox
                          disabled={
                            type === "shareOfVoice" &&
                            !coin.tweet_count &&
                            !coin.last_tweet_counts
                              ? true
                              : loading
                              ? true
                              : false
                          }
                          checked={
                            selectedCoins.includes(coin.coin_id) ? true : false
                          }
                          onClick={(e) => selectCoinHandler(e, coin.coin_id)}
                        />
                      )}
                      <img
                        src={getCoinImage(coin.coin_id)}
                        alt={coin.logo}
                        className={styles.coinLogo}
                      />
                      <Box>
                        <Typography
                          variant="caption"
                          component="h2"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          {coin.label}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="h3"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          {coin.title &&
                            coin.title[0]?.toUpperCase() +
                              coin.title.slice(1, 10)}
                          {coin.title?.length > 10 ? "..." : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {type !== "shareOfVoice" && (
                    <TableCell component="th" align="center">
                      <Typography
                        variant="caption"
                        component="h6"
                        sx={{ whiteSpace: "nowrap" }}
                        color={theme.palette.text.primary}
                      >
                        $
                        {
                          //@ts-ignore
                          formatPrice(coin.latest_price) > 1
                            ? Number(
                                formatPrice(coin.latest_price)
                              ).toLocaleString()
                            : formatPrice(coin.latest_price)
                        }
                      </Typography>
                    </TableCell>
                  )}
                  {type !== "marketcapWeighted" && (
                    <TableCell component="th" align="center">
                      {coin.signal && Object.keys(coin.signal).length > 0 ? (
                        <StatisticsGuage
                          value={calculateGuageValue(
                            coin.signal[0] ?? 0,
                            coin.signal[1] ?? 0,
                            coin.signal.null ?? 0
                          )}
                        />
                      ) : (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{
                            whiteSpace: "nowrap",
                            color: theme.palette.text.primary,
                          }}
                        >
                          Coming soon
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  {type !== "shareOfVoice" && type !== "marketcapWeighted" && (
                    <TableCell component="th" align="center">
                      {coin.prices &&
                      //@ts-ignore
                      coin.prices !== {} &&
                      coin.prices.length > 0 ? (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{
                            whiteSpace: "nowrap",
                            color:
                              calculateDayPercentChange(coin.prices) < 0
                                ? "#FA637A"
                                : //@ts-ignore
                                  theme.palette.common.greenText,
                          }}
                        >
                          {calculateDayPercentChange(coin.prices) < 0
                            ? "-"
                            : "+"}
                          {formatPrice(
                            Math.abs(calculateDayPercentChange(coin.prices))
                          )}
                          %
                        </Typography>
                      ) : (
                        <Typography> - </Typography>
                      )}
                    </TableCell>
                  )}
                  {type !== "shareOfVoice" && type !== "marketcapWeighted" && (
                    <TableCell component="th" align="center">
                      {coin.top_influencers?.length > 0 ? (
                        <Box className={styles.coinsTopInfluencers}>
                          {coin.top_influencers
                            .slice(0, 3)
                            .map((inf: infType) => (
                              <Link
                                to={`/influencers/${inf.user_id_str}`}
                                key={`${coin.id}-${inf.user_id_str}`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <Avatar
                                  screenName={inf.screen_name}
                                  width={30}
                                  height={30}
                                />
                              </Link>
                            ))}
                        </Box>
                      ) : (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          -
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  {type !== "marketcapWeighted" && (
                    <TableCell component="th" align="center">
                      {coin.tweet_count ? (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          {Number(coin.tweet_count).toLocaleString()}{" "}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          0
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  {type !== "marketcapWeighted" && (
                    <TableCell component="th" align="center">
                      {coin.tweet_count ? (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                          color={
                            coin.last_tweet_counts / coin.tweet_count <= 0
                              ? " #FA637A"
                              : "#2FDB7D"
                          }
                        >
                          {coin.last_tweet_counts / coin.tweet_count <= 0
                            ? "-"
                            : "+"}
                          {formatPrice(
                            Math.abs(coin.last_tweet_counts / coin.tweet_count)
                          )}
                          %
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{
                            whiteSpace: "nowrap",
                            color: theme.palette.text.primary,
                          }}
                        >
                          Coming soon
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  {type !== "shareOfVoice" && (
                    <TableCell component="th" align="center">
                      {coin.market_cap ? (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          {formatNumber(coin.market_cap).toLocaleString()}{" "}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          Coming soon
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  {type !== "shareOfVoice" && (
                    <TableCell component="th" align="center">
                      {coin.volume ? (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          {formatNumber(coin.volume).toLocaleString()}{" "}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          component="h6"
                          sx={{ whiteSpace: "nowrap" }}
                          color={theme.palette.text.primary}
                        >
                          Coming soon
                        </Typography>
                      )}
                    </TableCell>
                  )}
                  {type !== "shareOfVoice" && type !== "marketcapWeighted" && (
                    <TableCell
                      component="th"
                      align="center"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        padding: "0",
                        height: "70px !important",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        className={`${styles.cryptoCardChart} smallAreaChartContainer`}
                      >
                        {coin.prices && (
                          <SmallAreaChart
                            name="Coin"
                            data={[...coin.prices]
                              .reverse()
                              .map((price: any) => ({
                                x: price.date,
                                y: price.avg_price.toFixed(10),
                              }))}
                            color={
                              calculateWeekPercentChange(
                                coin.prices,
                                coin.latest_price
                              )
                                .toString()
                                .includes("-")
                                ? "#FA637A"
                                : //@ts-ignore
                                  theme.palette.common.greenText
                            }
                          />
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showCellInfo(itemInfo)}
    </Box>
  );
};

export default AssetsTable;
