import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CgMenuLeft } from "react-icons/cg";
import { FaCoins, FaUserCircle } from "react-icons/fa";
import { PiHouseFill } from "react-icons/pi";
import { BsFillFileTextFill } from "react-icons/bs";
import { HiMiniUserGroup } from "react-icons/hi2";
import { TbChartHistogram } from "react-icons/tb";
import Logo from "../../common/logo";
import styles from "../layout.module.scss";

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  const [state, setState] = useState(false);

  const menuItems = [
    {
      title: "Home",
      icon: <PiHouseFill size={20} />,
      link: "/",
    },
    {
      title: "Share of voice",
      icon: <FaCoins size={20} />,
      link: "/share-of-voice",
    },
    {
      title: "News",
      icon: <BsFillFileTextFill size={20} />,
      link: "/news",
    },
    {
      title: "Influencers",
      icon: <HiMiniUserGroup size={20} />,
      link: "/influencers",
    },
    {
      title: "Crypto index",
      icon: <TbChartHistogram size={20} />,
      link: "/crypto-index",
    },
  ];

  const menuItemsData = isSmallScreen ? menuItems.slice(-1) : menuItems;

  const toggleDrawer = (open: boolean) => {
    setState(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250, paddingTop: "2rem" }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <Box
        sx={{
          paddingLeft: "1rem",
          marginBottom: "2rem",
        }}
      >
        <Logo isAuth />
      </Box>
      <List>
        {menuItemsData.map((menuItem) => (
          <ListItem key={menuItem.title} disablePadding>
            <ListItemButton
              sx={{ marginBottom: ".7rem" }}
              onClick={() => navigate(menuItem.link)}
            >
              <ListItemIcon
                sx={{
                  color:
                    //@ts-ignore
                    theme.palette.common.whiteToGreen,
                  minWidth: "45px",
                }}
              >
                {menuItem.icon}
              </ListItemIcon>
              <Typography
                variant="body1"
                component="h3"
                sx={{
                  color:
                    //@ts-ignore
                    theme.palette.common.whiteToGreen,
                }}
              >
                {menuItem.title}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box className={styles.headerWrapper}>
      <IconButton onClick={() => toggleDrawer(true)}>
        <CgMenuLeft
          size={28} //@ts-ignore
          color={theme.palette.common.whiteToGreen}
        />
      </IconButton>
      <Drawer
        anchor="left"
        open={state}
        onClose={() => toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            background:
              //@ts-ignore
              theme.palette.common.popup,
          },
        }}
      >
        {list()}
      </Drawer>

      <Logo isDashboard />

      <IconButton aria-label="Menu" size="small">
        <Typography
          component={Link}
          to="/account"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaUserCircle
            size={30}
            //@ts-ignore
            color={theme.palette.common.whiteToGreen}
          />
        </Typography>
      </IconButton>
    </Box>
  );
};

export default Header;
