import axios from "axios";
import http from "../services/http";
const BASE_URL = process.env.REACT_APP_BASE_URL;

interface loginWithEmailProps {
  email: string;
  password: string;
}

interface signUpWithEmailProps {
  email: string;
  password1: string;
  password2: string;
}

interface resendVerificationEmailProps {
  email: string;
}

interface resetPasswordProps {
  email: string;
}

interface changePasswordProps {
  new_password1: string;
  new_password2: string;
  old_password: string;
}

interface updateUserProps {
  first_name: string;
  last_name: string;
  phone_number: string | number;
  email: string;
}

export const loginWithEmail = ({ email, password }: loginWithEmailProps) => {
  const url = `${BASE_URL}/auth/login/`;
  const requestBody = { email, password };

  const request = axios
    .post(url, requestBody)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const getUserData = () => {
  const url = "/auth/user/";

  return http.get(url);
};

export const signUpWithEmail = async ({
  email,
  password1,
  password2,
}: signUpWithEmailProps) => {
  const url = `${BASE_URL}/auth/registration/`;
  const requestBody = { email, password1, password2 };

  const request = axios
    .post(url, requestBody)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const resendVerificationEmail = ({
  email,
}: resendVerificationEmailProps) => {
  const url = `${BASE_URL}/auth/registration/resend-email/`;
  const requestBody = { email };

  const request = axios
    .post(url, requestBody)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const resetPassword = ({ email }: resetPasswordProps) => {
  const url = `${BASE_URL}/auth/password/reset/`;
  const requestBody = { email };

  const request = axios
    .post(url, requestBody)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};

export const checkAuthToken = (token: string) => {
  const url = "/auth/token/verify/";
  const requestBody = { token };

  return http.post(url, requestBody);
};

export const logout = () => {
  const url = "/auth/logout/";

  return http.post(url);
};

export const changePassword = ({
  new_password1,
  new_password2,
  old_password,
}: changePasswordProps) => {
  const url = "/auth/password/change/";
  const requestBody = { new_password1, new_password2, old_password };

  return http.post(url, requestBody);
};

export const updateUserData = ({
  first_name,
  last_name,
  phone_number,
  email,
}: updateUserProps) => {
  const url = "/auth/user/";
  const requestBody = { first_name, last_name, phone_number, email };

  return http.put(url, requestBody);
};

export const deactivateAccount = () => {
  const url = "/auth/user/";
  const requestBody = { is_active: 0 };

  return http.put(url, requestBody);
};
