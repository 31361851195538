import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import CardTitle from "../../common/title/CardTitle";
import { RootState } from "../../../features/store";
import { getCoinImage } from "../../../utils/functions";
import styles from "../influencer.module.scss";

interface CoinMentionProps {
  data: {} | undefined;
}

const CoinMention: React.FC<CoinMentionProps> = ({ data }) => {
  const theme = useTheme();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  function getCoinMention(
    mentionedCoinsData: { [x: string]: any } | undefined,
    availableCoinsData: any[]
  ) {
    const result = [];

    for (const key in mentionedCoinsData) {
      const coinId = parseInt(key);
      const foundCount = mentionedCoinsData[key];

      const coinInfo = availableCoinsData.find(
        (coin: { coin_id: number }) => coin.coin_id === coinId
      );

      if (coinInfo) {
        let totalValue = Object.values(mentionedCoinsData).reduce(
          (acc, val) => acc + val,
          0
        );

        const { logo, title, coin_id } = coinInfo;
        result.push({
          logo,
          title,
          coin_id,
          foundCount,
          foundPercent: (
            (mentionedCoinsData[coin_id] / totalValue) *
            100
          ).toFixed(0),
        });
      }
    }

    return result.sort(
      (a: { foundCount: number }, b: { foundCount: number }) =>
        b.foundCount - a.foundCount
    );
  }

  if (getCoinMention(data, cryptosState.availableCoins).length > 0) {
    return (
      <Box mt={5}>
        <CardTitle small hasInfo hasMargin>
          Topic Influence
        </CardTitle>
        <Box className={styles.coinsWrapper}>
          {getCoinMention(data, cryptosState.availableCoins)
            ?.slice(0, 9)
            .map((coin) => (
              <Box key={coin.coin_id}>
                <img src={getCoinImage(coin.coin_id)} alt={coin.title} />
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {coin.foundPercent}%
                </Typography>
              </Box>
            ))}
          {getCoinMention(data, cryptosState.availableCoins).length > 9 && (
            <Typography variant="body1" color={theme.palette.primary.main}>
              +{getCoinMention(data, cryptosState.availableCoins).length - 9}
            </Typography>
          )}
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

export default CoinMention;
