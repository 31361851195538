import { alpha, styled, useTheme } from "@mui/material/styles";
import { Typography, InputBase, Box, FormControl } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 30,
    position: "relative",
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.2)"
        : "rgba(217, 215, 215, 0.2)",
    fontSize: 13,
    width: "auto",
    padding: "10px 12px",
    boxSizing: "border-box",
    textAlign: "center",
    minHeight: "38px",
    maxWidth: "170px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: "Poppins",
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function EarlyAccessCode() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          marginRight: "10px",
          color: theme.palette.text.primary,
        }}
      >
        Early Access Code:
      </Typography>
      <FormControl variant="standard">
        <BootstrapInput defaultValue="" id="early-access-code-input" />
      </FormControl>
    </Box>
  );
}
