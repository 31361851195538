import { Box, Typography, useTheme } from "@mui/material";
import { formatPrice, getCoinImage } from "../../../utils/functions";
import styles from "../crypto.module.scss";

interface CryptoDataProps {
  data: {
    symbol: string;
    name: string;
    priceUsd: number;
  };
}

interface HistoricalDataProps {
  data: { priceUsd: string }[];
}

interface CryptoHeaderProps {
  data: CryptoDataProps;
  historicalData: HistoricalDataProps;
  coinId: number;
}

const CryptoHeader: React.FC<CryptoHeaderProps> = ({
  data,
  historicalData,
  coinId,
}) => {
  const theme = useTheme();

  return (
    <Box className={styles.cryptoHeaderWrapper}>
      <img src={getCoinImage(coinId)} alt={data?.data.symbol} />
      <Box>
        <Typography
          variant="h6"
          component="h1"
          sx={{ color: theme.palette.text.primary }}
        >
          {data?.data?.name}
        </Typography>
        <Typography
          variant="body2"
          component="h2"
          sx={{ color: theme.palette.text.primary }}
        >
          {data?.data?.symbol}
        </Typography>
      </Box>
      {historicalData && (
        <Box>
          <Typography
            variant="h6"
            component="h3"
            sx={{ color: theme.palette.text.primary }}
            align="right"
          >
            $
            {
              //@ts-ignore
              formatPrice(data?.data?.priceUsd) > 1
                ? Number(formatPrice(data?.data?.priceUsd)).toLocaleString()
                : formatPrice(data?.data?.priceUsd)
            }
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CryptoHeader;
