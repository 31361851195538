import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Loading from "../../components/common/loading";
import CardTitle from "../../components/common/title/CardTitle";
import ComparisonChart from "../../components/shareOfVoice/comparisonChart";
import AssetsTable from "../../components/common/assetsTable";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { setTweetsCountResult } from "../../features/crypto/cryptoSlice";
import {
  fetchCoinsStatistics,
  setCoinStatistics,
} from "../../features/crypto/cryptosSlice";
import { fetchCryptoTweets } from "../../api/crypto-tweets";

interface ItemType {
  id: number;
}

const ShareOfVoice = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const [loading, setLoading] = useState(false);
  const [selectedCoins, setSelectedCoins] = useState<any>(
    cryptoState.tweetsCountResult
      ? cryptoState.tweetsCountResult
          .slice(0, 3)
          .map((item: ItemType) => item.id)
      : [3, 6]
  );

  const fetchCryptoTweetsFunction = async (coinId: number) => {
    const cryptoTweets = await fetchCryptoTweets(coinId);
    return cryptoTweets;
  };

  useEffect(() => {
    if (!cryptosState.coinsStatistics && cryptosState.availableCoins) {
      dispatch(fetchCoinsStatistics(1)).then((res) =>
        dispatch(
          setCoinStatistics({
            page: 1,
            result: cryptosState.coinStatisticsState.result.concat(res.payload),
          })
        )
      );
    }
  }, [cryptosState.availableCoins]);

  useEffect(() => {
    if (cryptosState.availableCoins) {
      if (cryptoState.tweetsCountResult) {
        const coinsToFetch = selectedCoins.filter((coinId: number) => {
          return !cryptoState.tweetsCountResult.find(
            (item: ItemType) => item.id === coinId
          );
        });

        if (coinsToFetch.length > 0) {
          setLoading(true);
          const promises = coinsToFetch.map((coinId: number) =>
            fetchCryptoTweetsFunction(coinId)
          );
          Promise.all(promises)
            .then((results) => {
              setLoading(false);

              const newTweets = results.map((response, index) => ({
                id: coinsToFetch[index],
                data: response.data?.response.data,
              }));
              dispatch(
                setTweetsCountResult([
                  ...cryptoState.tweetsCountResult,
                  ...newTweets,
                ])
              );
            })
            .catch((error) => {});
        }
      } else {
        const promises = selectedCoins.map((number: number) =>
          fetchCryptoTweetsFunction(number)
        );
        Promise.all(promises).then((results) => {
          const tweets = results.map((response, index) => ({
            id: selectedCoins[index],
            data: response?.data?.response?.data,
          }));
          dispatch(setTweetsCountResult(tweets));
        });
      }
    }
  }, [
    cryptosState.availableCoins,
    selectedCoins,
    cryptoState.tweetsCountResult,
  ]);

  return (
    <DashboardLayout title="Coinfident | Share of voice">
      <Box mt={3}>
        <CardTitle
          isCenter
          hasInfo
          isHtml
          infoTitle="Share of voice"
          info={`Share of Voice provides a complete view of each coin's presence in the crypto space:<br /> 
        - Bar charts showcasing the weekly volume of posts by crypto influencers for each specific coin.<br /> 
        -The Percentage Reveals Share of Voice Changes for Each Coin Over the Past 7 Days, Offering Real-Time Insights into Community Engagement Trends.`}
        >
          Share of voice
        </CardTitle>

        <Box>
          {!cryptosState.coinStatisticsState.result ||
          cryptosState.coinStatisticsState.result.length < 2 ? (
            <Loading hasBackground />
          ) : (
            <>
              {!cryptoState.tweetsCountResult || loading ? (
                <Loading hasBackground />
              ) : (
                <ComparisonChart
                  name="Tweets"
                  selectedCoins={selectedCoins}
                  data={cryptoState.tweetsCountResult}
                />
              )}
              <AssetsTable
                type="shareOfVoice"
                loading={loading}
                data={cryptosState.coinStatisticsState.result}
                selectedCoins={selectedCoins}
                setSelectedCoins={setSelectedCoins}
              />
            </>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ShareOfVoice;
