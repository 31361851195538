import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import { Box, Container } from "@mui/material";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { fetchAvailableCoins } from "../../features/crypto/cryptosSlice";
const { Helmet } = require("react-helmet");

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

const DashboardLayout: React.FC<LayoutProps> = ({
  children,
  title,
  description,
}) => {
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const dispatch: AppDispatch = useAppDispatch();

  useEffect(() => {
    if (!coinsList.availableCoins) {
      dispatch(fetchAvailableCoins());
    }
  }, []);

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
      </Helmet>
      <Fade>
        <Container
          style={{
            // maxWidth: "1100px",
            padding: 0,
            position: "relative",
            zIndex: 10,
          }}
          maxWidth="xl"
        >
          <Header />
          <Box
            sx={{
              flex: 1,
              position: "relative",
              padding: "20px 0",
              paddingBottom: "80px",
              zIndex: 100,
            }}
          >
            {children}
          </Box>
          <Footer />
        </Container>
      </Fade>
    </>
  );
};

export default DashboardLayout;
