import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";

export interface InfluencersState {
  influencersFilter: null | string;
  influencersList: null | {
    results: [];
  };
  influencerData: null | {};
  topInfluencers: null | {};
  pinnedInfluencers: null | {};
  influencerDataLoading: boolean;
  influencersListLoading: boolean;
  topInfluencersLoading: boolean;
  pinnedInfluencersLoading: boolean;
  error: string | null;
}

export const fetchInfluencersList = createAsyncThunk<
  { data: null },
  { ordering: string; page: number },
  { rejectValue: any }
>("influencers list", async (requestData, thunkAPI) => {
  try {
    const response = await http.get(
      `/crypto/get-influencers-list/?ordering=${requestData.ordering}&user_type=influencer&page=${requestData.page}`
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInfluencerData = createAsyncThunk<
  { data: null },
  { id: string | undefined; page: number | string | undefined },
  { rejectValue: any }
>("influencer data", async (requestData, thunkAPI) => {
  try {
    const response = await http.post(
      `/crypto/get-influencer-statistics/?page=${requestData.page}`,
      {
        influencer: requestData.id,
      }
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPinnedInfluencers = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("pinned influencers", async (_, thunkAPI) => {
  try {
    const response = await http.get(`/crypto/pinned-influencer-list/`);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchTopInfluencers = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("top influencers", async (_, thunkAPI) => {
  try {
    const response = await http.get("/crypto/get-top-influencers/");
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: InfluencersState = {
  influencersFilter: null,
  influencersList: null,
  influencerData: null,
  topInfluencers: null,
  pinnedInfluencers: null,
  influencerDataLoading: true,
  influencersListLoading: true,
  topInfluencersLoading: true,
  pinnedInfluencersLoading: true,
  error: null,
};

export const influencersSlice = createSlice({
  name: "influencers",
  initialState,
  reducers: {
    setInfluencersFilter: (state, action) => {
      state.influencersFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencersList.pending, (state) => {
        state.influencersListLoading = true;
        state.error = null;
      })
      .addCase(fetchInfluencersList.fulfilled, (state, action) => {
        state.influencersListLoading = false;
        //@ts-ignore
        state.influencersList = action.payload;
      })
      .addCase(fetchInfluencersList.rejected, (state, action) => {
        state.influencersListLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchInfluencerData.pending, (state) => {
        state.influencerDataLoading = true;
        state.error = null;
      })
      .addCase(fetchInfluencerData.fulfilled, (state, action) => {
        state.influencerDataLoading = false;
        //@ts-ignore
        state.influencerData = action.payload;
      })
      .addCase(fetchInfluencerData.rejected, (state, action) => {
        state.influencerDataLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchTopInfluencers.pending, (state) => {
        state.topInfluencersLoading = true;
        state.error = null;
      })
      .addCase(fetchTopInfluencers.fulfilled, (state, action) => {
        state.topInfluencersLoading = false;
        state.topInfluencers = action.payload;
      })
      .addCase(fetchTopInfluencers.rejected, (state, action) => {
        state.topInfluencersLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchPinnedInfluencers.pending, (state) => {
        state.pinnedInfluencersLoading = true;
        state.error = null;
      })
      .addCase(fetchPinnedInfluencers.fulfilled, (state, action) => {
        state.pinnedInfluencersLoading = false;
        state.pinnedInfluencers = action.payload;
      })
      .addCase(fetchPinnedInfluencers.rejected, (state, action) => {
        state.pinnedInfluencersLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default influencersSlice.reducer;

export const { setInfluencersFilter } = influencersSlice.actions;
