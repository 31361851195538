import { Box } from "@mui/material";
import Loading from "../../common/loading";
import CardTitle from "../../common/title/CardTitle";
import AssetsTable from "../../common/assetsTable";

const AssetsData: React.FC<{ data: any }> = ({ data }) => {
  if (!data.coinStatisticsState) {
    return <Loading hasBackground />;
  } else {
    return (
      <>
        <Box mt={5}>
          <CardTitle hasInfo>Cryptocurrencies</CardTitle>
          {!data.coinStatisticsState.result ||
          (data.coinStatisticsState.result &&
            data.coinStatisticsState.result < 1) ? (
            <Loading hasBackground />
          ) : (
            <AssetsTable type="Home" data={data.coinStatisticsState.result} />
          )}
        </Box>
      </>
    );
  }
};

export default AssetsData;
