import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00CCB4",
    },
    secondary: {
      main: "#00D9a5",
    },
    text: {
      primary: "#033127",
      secondary: "#31574C",
      disabled: "linear-gradient(180deg, #636563 0%, #2e3030 100%)",
    },
    background: {
      default: "#fff",
      paper: "linear-gradient(180deg, #02776BCF 0%, #00C8B080 100%)",
    },
    error: {
      main: "#fa637a",
    },
    warning: {
      main: "#75D6A1",
    },
    common: {
      white: "#fff",
      blackToWhite: "#fff",
      card: "linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(244, 244, 244, 0.29) 0.01%, rgba(4, 170, 142, 0.30) 100%)",
      solidCard: "linear-gradient(180deg, #02776BCF 0%, #00C8B080 100%)",
      bottomDrawer: "linear-gradient(180deg, #02776BCF 0%, #00C8B080 100%)",
      footer: "rgba(255, 255, 255, 0.7)",
      selectedFooterItem: "rgba(179, 201, 195, 0.42)",
      icon: "#00d9c7",
      greenToWhite: "#fff",
      whiteToGreen: "#048E74",
      whiteToDarkGreen: "#1D584D",
      green: "#048E74",
      greenText: "#004f16",
      backdrop: "rgba(255, 255, 255, 0.9)",
      popup: "linear-gradient(180deg, #eee 0%, #fff 100%)",
      mute: "#555",
      warningText: "#FFD600",
      authButton: "#fff",
      primaryToGreen: "#004f16",
      primaryToLightGreen: "#00FBBF",
    },
    grey: {
      main: "rgba(217, 215, 215, 1)",
      light: "rgba(0, 0, 0, 0.2)",
      dark: "rgba(0, 0, 0, 0.7)",
      700: "rgba(0, 0, 0, 0.2)",
      400: "#D9D7D7",
    },
  },
  typography: {
    allVariants: {
      color: "#fff",
    },
    fontFamily: "Poppins",
  },
});
