import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Box, Button } from "@mui/material";
import CardTitle from "../../common/title/CardTitle";
import WalletTransaction from "./walletTransaction";
import styles from "../botDetection.module.scss";

const Transactions: React.FC<{ data: any }> = ({ data }) => {
  const [showItems, setShowItems] = useState(5);

  return (
    <Box mt={9}>
      <CardTitle isCenter>Wallet info</CardTitle>
      <Box mt={3}>
        <Fade>
          <Box className={styles.transaction}>
            {data
              .slice(0, showItems)
              .map((transaction: string, idx: number) => (
                <WalletTransaction data={transaction} key={idx} />
              ))}
            {showItems < data.length && (
              <Box display="flex" justifyContent="center" mt={2} mb={5}>
                <Button onClick={() => setShowItems(data.length)}>
                  Show more
                </Button>
              </Box>
            )}
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

export default Transactions;
