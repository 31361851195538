import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";

interface BotDetectionDataProps {
  wallet_address: string;
  network?: string;
}

export interface WalletState {
  wallet_address: null | string;
  wallet_network: null | string;
  botNetwork: null | [];
  botDetection: null | {
    wallet_history: [];
    GPT_result: string | number;
  };
  botNetworkLoading: boolean;
  botDetectionLoading: boolean;
  error: string | null;
}

export const fetchBotNetwork = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("bot network", async (_, thunkAPI) => {
  try {
    const response = await http.get("crypto/get_bot_detection_network/");
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchBotDetection = createAsyncThunk<
  { data: null },
  BotDetectionDataProps,
  { rejectValue: any }
>("bot detection", async (requestData, thunkAPI) => {
  try {
    const response = await http.post(
      `crypto/bot_detection_api/?wallet_address=${requestData.wallet_address}${
        requestData.network ? `&network=${requestData.network}` : ""
      }`
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: WalletState = {
  wallet_address: null,
  wallet_network: null,
  botDetection: null,
  botNetwork: null,
  botNetworkLoading: true,
  botDetectionLoading: false,
  error: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
      state.wallet_address = action.payload;
    },
    setWalletNetwork: (state, action) => {
      state.wallet_network = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBotNetwork.pending, (state) => {
        state.botNetworkLoading = true;
        state.error = null;
      })
      .addCase(fetchBotNetwork.fulfilled, (state, action) => {
        state.botNetworkLoading = false;
        //@ts-ignore
        state.botNetwork = action.payload;
      })
      .addCase(fetchBotNetwork.rejected, (state, action) => {
        state.botNetworkLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchBotDetection.pending, (state) => {
        state.botDetectionLoading = true;
        state.error = null;
      })
      .addCase(fetchBotDetection.fulfilled, (state, action) => {
        state.botDetectionLoading = false;
        //@ts-ignore
        state.botDetection = action.payload;
      })
      .addCase(fetchBotDetection.rejected, (state, action) => {
        state.botDetectionLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default walletSlice.reducer;

export const { setWalletAddress, setWalletNetwork } = walletSlice.actions;
