import { useState, useRef } from "react";
import { Box } from "@mui/material";
import Loading from "../../common/loading";
import CardTitle from "../../common/title/CardTitle";
import FullPageBlur from "../../common/fullPageBlur";
import InfluencerCard from "../../influencers/influencerCard";

interface InfluencerDataType {
  id_str: number;
  header: string;
  logo: string;
  name: string;
  screen_name: string;
}

interface PinnedInfluencersProps {
  influencersList: {
    pinnedInfluencersLoading: boolean;
    pinnedInfluencers: {
      results: [];
    };
  };
  styles: any;
}

const PinnedInfluencers: React.FC<PinnedInfluencersProps> = ({
  influencersList,
  styles,
}) => {
  const clickedBtnRef = useRef();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [clickedInfluencerData, setClickedInfluencerData] = useState<null | {
    id_str: string | number;
    screen_name: string;
  }>(null);

  return (
    <>
      <FullPageBlur
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        closeFullBlurFunction={() => setClickedInfluencerData(null)}
      />
      <FullPageBlur isVisible={isFilterOpen} setIsVisible={setIsFilterOpen} />
      <Box mt={3} className={styles.influencersPage} ref={clickedBtnRef}>
        {influencersList.pinnedInfluencersLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "1rem auto",
            }}
          >
            <Loading />
          </Box>
        ) : influencersList.pinnedInfluencers?.results &&
          influencersList.pinnedInfluencers.results.length > 0 ? (
          <Box sx={{ margin: "1rem auto 5rem" }}>
            <CardTitle isCenter={false}>Pinned Influencers</CardTitle>
            <Box className={styles.influencersWrapper}>
              {influencersList.pinnedInfluencers?.results.map(
                (inf: InfluencerDataType) => (
                  <InfluencerCard
                    key={inf.id_str}
                    influencerData={inf}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    clickedInfluencerData={clickedInfluencerData}
                    setClickedInfluencerData={setClickedInfluencerData}
                    isPinned
                  />
                )
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default PinnedInfluencers;
