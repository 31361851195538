import { Box, Typography, useTheme } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import styles from "./guage.module.scss";

const StatisticsGuage: React.FC<{
  value: number;
  hasBackground?: boolean;
  isEmpty?: boolean;
}> = ({ value, hasBackground, isEmpty }) => {
  const theme = useTheme();

  return (
    <Box className={styles.statisticsGaugeWrapper}>
      <Typography
        className={styles.statisticsValue}
        sx={{
          color: hasBackground
            ? //@ts-ignore
              theme.palette.common.white
            : theme.palette.text.primary,
        }}
      >
        {isEmpty ? "No data" : `${value}%`}
      </Typography>
      <GaugeComponent
        style={{ width: "110px", height: "50px" }}
        type="semicircle"
        value={value ?? 0}
        arc={{
          colorArray: isEmpty
            ? [theme.palette.grey["700"]]
            : ["#FA637A", "#CDD931", "#259659"],
          padding: 0,
          width: 0.15,
          gradient: true,
        }}
        pointer={{
          elastic: true,
          length: isEmpty ? 0 : 0.65,
          width: isEmpty ? 0 : 8,
          //@ts-ignore
          color: hasBackground
            ? //@ts-ignore
              theme.palette.common.white
            : theme.palette.text.primary,
        }}
        labels={{
          valueLabel: {
            hide: true,
          },
          tickLabels: {
            hideMinMax: true,
            defaultTickLineConfig: {
              char: "",
            },
          },
        }}
      />
    </Box>
  );
};

export default StatisticsGuage;
