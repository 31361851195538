import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Loading from "../../components/common/loading";
import CardTitle from "../../components/common/title/CardTitle";
import ComparisonChart from "../../components/marketcapWeighted/comparisonChart";
import AssetsTable from "../../components/common/assetsTable";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { setMarketcapWeightedResult } from "../../features/crypto/cryptoSlice";
import {
  fetchCoinsMarketcapWeighted,
  fetchCoinsStatistics,
  setCoinStatistics,
} from "../../features/crypto/cryptosSlice";
import { fetchCryptoInnovativeParameter } from "../../api/innovativeParameter";
import MarketcapTreemapChart from "../../components/charts/MarketcapTreemapChart";

interface ItemType {
  id: number;
}

const MarketcapWeighted = () => {
  const dispatch: AppDispatch = useAppDispatch();
  // const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  // const [loading, setLoading] = useState(false);
  // const [selectedCoins, setSelectedCoins] = useState<any>(
  //   cryptoState.marketcapWeightedResult
  //     ? cryptoState.marketcapWeightedResult
  //         .slice(0, 3)
  //         .map((item: ItemType) => item.id)
  //     : [3]
  // );

  // const fetchCryptoInnovativeParameterFunction = async (coinId: number) => {
  //   const cryptoMarketcapWeighted = await fetchCryptoInnovativeParameter(
  //     coinId
  //   );
  //   return cryptoMarketcapWeighted;
  // };

  // useEffect(() => {
  //   if (!cryptosState.coinsStatistics && cryptosState.availableCoins) {
  //     dispatch(fetchCoinsStatistics(1)).then((res) =>
  //       dispatch(
  //         setCoinStatistics({
  //           page: 1,
  //           result: cryptosState.coinStatisticsState.result.concat(res.payload),
  //         })
  //       )
  //     );
  //   }
  // }, [cryptosState.availableCoins]);

  // useEffect(() => {
  //   if (cryptosState.availableCoins) {
  //     if (cryptoState.marketcapWeightedResult) {
  //       const coinsToFetch = selectedCoins.filter((coinId: number) => {
  //         return !cryptoState.marketcapWeightedResult.find(
  //           (item: ItemType) => item.id === coinId
  //         );
  //       });

  //       if (coinsToFetch.length > 0) {
  //         setLoading(true);
  //         const promises = coinsToFetch.map((coinId: number) =>
  //           fetchCryptoInnovativeParameterFunction(coinId)
  //         );
  //         Promise.all(promises)
  //           .then((results) => {
  //             setLoading(false);

  //             const newMarketcapWeighted = results.map((response, index) => ({
  //               id: coinsToFetch[index],
  //               data: response.data?.response.data.results,
  //             }));
  //             dispatch(
  //               setMarketcapWeightedResult([
  //                 ...cryptoState.marketcapWeightedResult,
  //                 ...newMarketcapWeighted,
  //               ])
  //             );
  //           })
  //           .catch((error) => {});
  //       }
  //     } else {
  //       const promises = selectedCoins.map((number: number) =>
  //         fetchCryptoInnovativeParameterFunction(number)
  //       );
  //       Promise.all(promises).then((results) => {
  //         const marketcapWeighted = results.map((response, index) => ({
  //           id: selectedCoins[index],
  //           data: response?.data?.response?.data?.results,
  //         }));
  //         dispatch(setMarketcapWeightedResult(marketcapWeighted));
  //       });
  //     }
  //   }
  // }, [
  //   cryptosState.availableCoins,
  //   selectedCoins,
  //   cryptoState.marketcapWeightedResult,
  // ]);

  useEffect(() => {
    if (cryptosState.availableCoins) {
      dispatch(fetchCoinsMarketcapWeighted());
    }
  }, [cryptosState.availableCoins]);

  return (
    <DashboardLayout title="Coinfident | Crypto Index">
      <Box mt={3}>
        <CardTitle isCenter>Crypto Index</CardTitle>

        {cryptosState.marketcapWeightedLoading ? (
          <Loading hasBackground />
        ) : (
          cryptosState.marketcapWeighted && (
            <Box>
              <MarketcapTreemapChart
                data={cryptosState.marketcapWeighted.results}
                name="Crypto Index"
              />
            </Box>
          )
        )}

        {/* <Box>
          {!cryptosState.coinStatisticsState.result ||
          cryptosState.coinStatisticsState.result.length < 2 ? (
            <Loading hasBackground />
          ) : (
            <>
              {!cryptoState.marketcapWeightedResult || loading ? (
                <Loading hasBackground />
              ) : (
                <ComparisonChart
                  name="Crypto Index"
                  selectedCoins={selectedCoins}
                  data={cryptoState.marketcapWeightedResult}
                />
              )}
              <AssetsTable
                type="marketcapWeighted"
                loading={loading}
                data={cryptosState.coinStatisticsState.result}
                selectedCoins={selectedCoins}
                setSelectedCoins={setSelectedCoins}
              />
            </>
          )}
        </Box> */}
      </Box>
    </DashboardLayout>
  );
};

export default MarketcapWeighted;
