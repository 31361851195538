import { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { FiChevronDown } from "react-icons/fi";
import styles from "./transactions.module.scss";

interface TransactionCardProps {
  data: {
    title: string;
    date: string;
    time: string;
    price: number;
    details: string;
  };
}

const TransactionCard: React.FC<TransactionCardProps> = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [isCardExpandable, setIsCardExpandable] = useState(true);

  const theme = useTheme();
  const transactionTextElement = useRef();

  const checkIfCardIsExpandable = () => {
    if (!data.details) {
      setIsCardExpandable(false);
      setExpanded(true);
    }
  };

  useEffect(() => {
    checkIfCardIsExpandable();
  }, []);

  return (
    <Box
      className={styles.transactionCard}
      sx={{
        background: theme.palette.background.paper,
        paddingTop: isCardExpandable ? "40px" : "20px",
      }}
    >
      <Accordion
        expanded={expanded}
        sx={{
          boxShadow: "none",
          background: "transparent",
          width: "100%",
          "& .MuiCollapse-root": {
            visibility: "visible",
            minHeight: "50px !important",
          },
        }}
      >
        <AccordionSummary
          sx={{ minHeight: "0 !important", height: "0 !important" }}
        />
        <AccordionDetails className={styles.transactionCardWrapper}>
          <Box
            className={styles.transactionCardTitle}
            sx={{ background: "transparent" }}
            onClick={() => data.details && setExpanded(!expanded)}
          >
            <Box>
              <Typography
                variant="body2"
                component="h2"
                sx={{ color: theme.palette.common.white }}
              >
                {data.title}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey["700"]
                      : theme.palette.common.white,
                }}
              >
                {data.date}-{data.time}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                component="h3"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey["700"]
                      : theme.palette.common.white,
                }}
              >
                Price:
              </Typography>
              <Typography
                variant="caption"
                component="h4"
                sx={{
                  //@ts-ignore
                  color: theme.palette.common.primaryToGreen,
                }}
              >
                ${data.price}
              </Typography>
            </Box>
          </Box>
          {data.details && (
            <Typography
              variant="body2"
              component="p"
              //@ts-ignore
              ref={transactionTextElement}
              sx={{
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey["400"]
                    : //@ts-ignore
                      theme.palette.common.whiteToDarkGreen,
                borderTop: `1px solid ${theme.palette.grey["700"]}`,
              }}
              onClick={() => setExpanded(!expanded)}
            >
              {data.details}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {isCardExpandable && (
        <Box
          className={styles.transactionCardExtraButton}
          onClick={() => setExpanded(!expanded)}
        >
          <FiChevronDown
            size={22}
            style={{
              transition: "270ms",
              transform: expanded ? "rotate(180deg)" : "rotate(0)",
              //@ts-ignore
              color: theme.palette.common.white,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default TransactionCard;
