import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { BiFilterAlt } from "react-icons/bi";
import FullPageBlur from "../../../common/fullPageBlur";
import CoinsList from "./CoinsList";
import Searchbox from "./Searchbox";
import SortList from "./SortList";
import styles from "../newsHeader.module.scss";

interface NewsListType {
  newsPageFilter: {
    twitterFilters: {
      ordering: string;
      selectedCoins: [];
    };
    rssFilters: {
      search: string;
    };
  };
}

interface twitterFiltersType {
  ordering: string;
  selectedCoins: {}[];
}

interface rssFiltersType {
  search: string;
  selectedCoins: {}[];
}

interface FilterNewsProps {
  newsList: NewsListType;
  newsType: string;
  filterChangeHandler: Function;
  twitterFilters: twitterFiltersType;
  setTwitterFilters: any;
  rssFilters: rssFiltersType;
  setRssFilters: any;
}

const FilterNews: React.FC<FilterNewsProps> = ({
  newsList,
  newsType,
  filterChangeHandler,
  twitterFilters,
  setTwitterFilters,
  rssFilters,
  setRssFilters,
}) => {
  const theme = useTheme();
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const filterNewsHandler = () => {
    if (newsType === "Rss") {
      filterChangeHandler(newsType, rssFilters);
    } else {
      filterChangeHandler(newsType, twitterFilters);
    }
    setShowFilterMenu(false);
  };

  useEffect(() => {
    setTwitterFilters({
      ordering: newsList.newsPageFilter?.twitterFilters
        ? newsList.newsPageFilter.twitterFilters.ordering
        : "-total",
      selectedCoins: newsList.newsPageFilter?.twitterFilters
        ? newsList.newsPageFilter.twitterFilters.selectedCoins
        : [],
    });

    setRssFilters({
      search: newsList.newsPageFilter?.rssFilters
        ? newsList.newsPageFilter.rssFilters.search
        : "",
      selectedCoins: [],
    });
  }, [newsList.newsPageFilter]);

  return (
    <Box>
      <IconButton onClick={() => setShowFilterMenu(!showFilterMenu)}>
        {newsType === "Twitter" ? (
          newsList.newsPageFilter?.twitterFilters &&
          (newsList.newsPageFilter.twitterFilters?.selectedCoins?.length > 0 ||
            newsList.newsPageFilter.twitterFilters?.ordering !== "-total") ? (
            <Badge badgeContent=" " color="primary" variant="dot">
              <BiFilterAlt size={20} />
            </Badge>
          ) : (
            <BiFilterAlt size={20} />
          )
        ) : newsType === "Rss" &&
          newsList.newsPageFilter?.rssFilters &&
          newsList.newsPageFilter.rssFilters.search !== "" ? (
          <Badge badgeContent=" " color="primary" variant="dot">
            <BiFilterAlt size={20} />
          </Badge>
        ) : (
          <BiFilterAlt size={20} />
        )}
      </IconButton>

      {showFilterMenu && (
        <Box className={styles.filterNewsMenu}>
          <Fade
            triggerOnce
            style={{
              display: "flex",
              flexDirection: "column",
              height: "52vh",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h6"
                align="center"
                mb={4}
                sx={{
                  color: theme.palette.text.primary,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <BiFilterAlt size={20} style={{ marginRight: "6px" }} /> Filters
              </Typography>
              {newsType === "Twitter" && (
                <SortList
                  twitterFilters={twitterFilters}
                  //@ts-ignore
                  setTwitterFilters={setTwitterFilters}
                />
              )}
              {newsType === "Rss" && (
                <Searchbox
                  rssFilters={rssFilters}
                  setRssFilters={setRssFilters}
                />
              )}
              {newsType === "Twitter" && (
                <CoinsList
                  newsType={newsType}
                  twitterFilters={twitterFilters}
                  setTwitterFilters={setTwitterFilters}
                />
              )}
            </Box>
            <Box className={styles.filterNewsAction}>
              <Button variant="contained" onClick={filterNewsHandler}>
                Filter
              </Button>
              <Button
                variant="contained"
                sx={{
                  //@ts-ignore
                  background: theme.palette.grey.light,
                }}
                onClick={() => setShowFilterMenu(false)}
              >
                Cancel
              </Button>
            </Box>
          </Fade>
        </Box>
      )}

      <FullPageBlur
        isVisible={showFilterMenu}
        setIsVisible={setShowFilterMenu}
      />
    </Box>
  );
};

export default FilterNews;
