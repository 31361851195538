import { Box } from "@mui/material";
import TransactionCard from "../../transactions";

interface TransactionType {
  id: number;
  title: string;
  date: string;
  time: string;
  price: number;
  details: string;
}

const Transactions = () => {
  const transactions: TransactionType[] = [
    {
      id: 1,
      title: "App Subscription",
      date: "2023/11/28",
      time: "12:39",
      price: 10000,
      details: "asasasas",
    },
  ];

  return (
    <>
      <Box mt={3}>
        {transactions.length > 0
          ? transactions.map((transaction: TransactionType) => (
              <TransactionCard data={transaction} key={transaction.id} />
            ))
          : ""}
      </Box>
    </>
  );
};

export default Transactions;
