import { useEffect } from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "../../layout/dashboardLayout";
import Movers from "../../components/home/movers";
import AiChatBot from "../../components/home/aiChatBot";
import AssetsData from "../../components/home/assetsData";
import { fetchAllMovers } from "../../features/crypto/moversSlice";
import {
  fetchCoinsStatistics,
  setCoinStatistics,
} from "../../features/crypto/cryptosSlice";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";

const Home = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const moversState: any = useSelector((state: RootState) => state.movers);

  useEffect(() => {
    if (cryptosState.availableCoins) {
      if (!cryptosState.coinsStatistics) {
        dispatch(fetchCoinsStatistics(1)).then((res) =>
          dispatch(
            setCoinStatistics({
              page: 1,
              result: cryptosState.coinStatisticsState.result.concat(
                res.payload
              ),
            })
          )
        );
      }
      if (!moversState.data) {
        dispatch(fetchAllMovers());
      }
    }
  }, [cryptosState.availableCoins]);

  return (
    <DashboardLayout title="Coinfident">
      <Movers moversState={moversState} />
      <AiChatBot />
      <AssetsData data={cryptosState} />

      {/* <RetweetGraph /> */}
    </DashboardLayout>
  );
};

export default Home;
