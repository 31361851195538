import * as Yup from "yup";

export const useCreateSchema = (activeItems: string[]) => {
  //@ts-ignore
  const shape: Yup.SchemaOf<any> = {};

  activeItems.forEach((item) => {
    switch (item) {
      case "email":
        shape.email = Yup.string()
          .email("Email is not valid")
          .required("This field is required");
        break;
      case "first_name":
        shape.first_name = Yup.string().required("This field is required");
        break;
      case "last_name":
        shape.last_name = Yup.string().required("This field is required");
        break;
      case "phone_number":
        shape.phone_number = Yup.string()
          .required("This field is required")
          .trim();
        break;
      case "password":
        shape.password = Yup.string().required("This field is required");
        break;
      case "old_password":
        shape.old_password = Yup.string().required("This field is required");
        break;
      case "password1":
        shape.password1 = Yup.string()
          .required("This field is required")
          .min(8, "Password is short")
          .matches(/^(?=.*[0-9])/, "Password must contain at least one number")
          .matches(
            /^(?=.*[A-Za-z])/,
            "Password must contain at least one letter"
          );
        break;
      case "password2":
        shape.password2 = Yup.string()
          .required("This field is required")
          .oneOf([Yup.ref("password1")], "Repeat password does not match");
        break;
      case "new_password1":
        shape.new_password1 = Yup.string()
          .required("This field is required")
          .min(8, "Password is short")
          .matches(/^(?=.*[0-9])/, "Password must contain at least one number")
          .matches(
            /^(?=.*[A-Za-z])/,
            "Password must contain at least one letter"
          );
        break;
      case "new_password2":
        shape.new_password2 = Yup.string()
          .required("This field is required")
          .oneOf([Yup.ref("new_password1")], "Repeat password does not match");
        break;
      case "search":
        shape.search = Yup.string().required("This field is required");
        break;
      case "wallet_address":
        shape.wallet_address = Yup.string().required("This field is required");
        break;
    }
  });

  return Yup.object().shape(shape);
};
