import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";

interface SupportAndResistanceProps {
  coin: string;
  start_date: string;
  end_date: string;
}

interface PriceActionPatternProps {
  coin: string;
  start_date: string;
  end_date: string;
}

export interface cryptoIndicatorsState {
  supportAndResistance: null | {};
  priceActionPattern: null | {};
  supportAndResistanceLoading: boolean;
  priceActionPatternLoading: boolean;
  error: string | null;
}

export const fetchSupportAndResistance = createAsyncThunk<
  { data: null },
  SupportAndResistanceProps,
  { rejectValue: any }
>("support and resistance", async (requestData, thunkAPI) => {
  try {
    const url = "/crypto/get-support-resistance/";
    const response = await http.post(url, requestData);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPriceActionPattern = createAsyncThunk<
  { data: null },
  PriceActionPatternProps,
  { rejectValue: any }
>("price action pattern", async (requestData, thunkAPI) => {
  try {
    const url = "/crypto/get-price-action-pattern/";
    const response = await http.post(url, requestData);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: cryptoIndicatorsState = {
  supportAndResistance: null,
  priceActionPattern: null,
  supportAndResistanceLoading: false,
  priceActionPatternLoading: false,
  error: null,
};

export const cryptoIndicatorsSlice = createSlice({
  name: "crypto indicators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportAndResistance.pending, (state) => {
        state.supportAndResistanceLoading = true;
        state.error = null;
      })
      .addCase(fetchSupportAndResistance.fulfilled, (state, action) => {
        state.supportAndResistanceLoading = false;
        state.supportAndResistance = action.payload;
      })
      .addCase(fetchSupportAndResistance.rejected, (state, action) => {
        state.supportAndResistanceLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchPriceActionPattern.pending, (state) => {
        state.priceActionPatternLoading = true;
        state.error = null;
      })
      .addCase(fetchPriceActionPattern.fulfilled, (state, action) => {
        state.priceActionPatternLoading = false;
        state.priceActionPattern = action.payload;
      })
      .addCase(fetchPriceActionPattern.rejected, (state, action) => {
        state.priceActionPatternLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default cryptoIndicatorsSlice.reducer;
