import { useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { LuInfo } from "react-icons/lu";
import BottomDrawer from "../bottomDrawer";
import FullPageBlur from "../fullPageBlur";

interface CardTitleProps {
  children: any;
  isCenter?: boolean;
  small?: boolean;
  hasInfo?: boolean;
  info?: string;
  infoTitle?: string;
  hasMargin?: boolean;
  isHtml?: boolean;
}

const CardTitle: React.FC<CardTitleProps> = ({
  children,
  isCenter,
  small,
  hasInfo,
  info,
  infoTitle,
  hasMargin,
  isHtml,
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent:
          hasInfo && isCenter ? "center" : hasInfo ? "space-between" : "center",
        alignItems: "center",
        marginBottom: hasMargin ? ".9rem" : 0,
      }}
    >
      <Typography
        component="h1"
        sx={{
          //@ts-ignore
          color: theme.palette.common.whiteToGreen,
          fontWeight: 300,
          fontSize: small ? "15px" : "17px",
          lineHeight: 1,
          marginRight: hasInfo ? ".2rem" : 0,
          display: "flex",
          alignItems: "center",
        }}
        align={isCenter ? "center" : "left"}
      >
        {children}
      </Typography>
      {hasInfo && infoTitle ? (
        <Box sx={{ position: "static", zIndex: 10 }}>
          <IconButton
            aria-label="Popup"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <LuInfo
              size={23}
              color={
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500]
              }
            />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
      <FullPageBlur isVisible={open} setIsVisible={setOpen} />
      <BottomDrawer open={open} setOpen={setOpen} isSolid>
        <Typography variant="h6" align="center" mb={1}>
          {infoTitle}
        </Typography>
        {isHtml ? (
          //@ts-ignore
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: info }}
          ></Typography>
        ) : (
          <Typography variant="body2">{info}</Typography>
        )}
      </BottomDrawer>
    </Box>
  );
};

export default CardTitle;
