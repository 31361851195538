import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { BiSend } from "react-icons/bi";
import { TfiLock } from "react-icons/tfi";
import CardTitle from "../../common/title/CardTitle";
import styles from "./aiChatBot.module.scss";

const AiChatBot = () => {
  const theme = useTheme();

  return (
    <Box my={5}>
      <CardTitle hasInfo>AI Crypto advisor</CardTitle>
      <Box className={styles.chatBotWrapper}>
        <Box className={styles.chatBotComingSoon}>
          <TfiLock
            size={50}
            style={{
              color: theme.palette.primary.main,
            }}
          />
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            Coming soon
          </Typography>
        </Box>
        <Box className={styles.chatBotButtons}>
          <Button sx={{ color: theme.palette.text.primary }}>
            Market Analysis
          </Button>
          <Button sx={{ color: theme.palette.text.primary }}>News</Button>
          <Button sx={{ color: theme.palette.text.primary }}>
            Legal and Regulation
          </Button>
          <Button sx={{ color: theme.palette.text.primary }}>
            Portfolio Management
          </Button>
        </Box>
        <Box
          className={styles.chatBotBox}
          sx={{
            background:
              //@ts-ignore
              theme.palette.common.solidCard,
          }}
        >
          <Typography variant="body2">
            Share an overview of today's crypto market
          </Typography>
          <Box className={styles.chatBotSendButton}>
            <IconButton>
              <BiSend style={{ color: theme.palette.grey[500] }} size={33} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AiChatBot;
