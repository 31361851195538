import moment from "moment";
import { Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import NewsCard from "../../common/newsCard";
import Loading from "../../common/loading";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import {
  fetchTweetsList,
  setNewsPageTweetsResult,
} from "../../../features/news/newsSlice";

interface TweetItemType {
  avatar: string;
  screen_name: string;
  created_at: string;
  full_text: string;
  user_id_str: string;
  id_str: string;
  reply_count: number;
  favorite_count: number;
  retweet_count: number;
  media: string;
}

interface TweetsNewsProps {
  setTweetsPage: React.Dispatch<React.SetStateAction<number>>;
  newsList: {
    tweetsDisplayResult: {
      next: null | string;
      results: [];
    };
    newsPageFilter: {
      twitterFilters: null | {
        ordering: string;
        selectedCoins: string;
      };
    };
  };
}

const TweetsNews: React.FC<TweetsNewsProps> = ({ setTweetsPage, newsList }) => {
  const dispatch: AppDispatch = useAppDispatch();

  const fetchNextPage = () => {
    const requestData = {
      page: newsList.tweetsDisplayResult?.next?.split("page=")[1],
      ordering: newsList.newsPageFilter
        ? newsList.newsPageFilter.twitterFilters?.ordering
        : "-total",
      selectedCoins: newsList.newsPageFilter
        ? newsList.newsPageFilter.twitterFilters?.selectedCoins
        : [],
    };

    setTweetsPage(
      Number(newsList.tweetsDisplayResult?.next?.split("page=")[1])
    );
    dispatch(fetchTweetsList(requestData)).then((response) => {
      dispatch(
        setNewsPageTweetsResult({
          count: response.payload.count,
          next: response.payload.next,
          results: [...newsList.tweetsDisplayResult.results].concat(
            response.payload.results
          ),
        })
      );
    });
  };

  return (
    <Box mt={3}>
      {!newsList.tweetsDisplayResult ? (
        <Loading />
      ) : (
        newsList.tweetsDisplayResult && (
          <InfiniteScroll
            dataLength={
              //@ts-ignore
              newsList.tweetsDisplayResult.results
                ? newsList.tweetsDisplayResult.results.length
                : 0
            }
            next={fetchNextPage}
            hasMore={newsList.tweetsDisplayResult.next ? true : false}
            loader={<Loading />}
          >
            {newsList.tweetsDisplayResult.results?.map(
              (newsItem: TweetItemType) => (
                <NewsCard
                  key={newsItem.id_str}
                  id={newsItem.user_id_str}
                  screen_name={newsItem.screen_name}
                  media={newsItem.media}
                  name={newsItem.screen_name}
                  date={moment
                    .parseZone(newsItem.created_at)
                    .format("DD MMMM YYYY")}
                  content={newsItem.full_text}
                  reply_count={newsItem.reply_count ? newsItem.reply_count : 0}
                  favorite_count={
                    newsItem.favorite_count ? newsItem.favorite_count : 0
                  }
                  retweet_count={
                    newsItem.retweet_count ? newsItem.retweet_count : 0
                  }
                />
              )
            )}
          </InfiniteScroll>
        )
      )}
    </Box>
  );
};

export default TweetsNews;
