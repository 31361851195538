import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, useTheme } from "@mui/material";
import Logo from "../../../components/common/logo";
import coinLogo from "../../../assets/images/icons/coin.png";
import sideShape from "../../../assets/images/icons/side_shape.svg";
import bIcon from "../../../assets/images/icons/b_icon.svg";
import Stars from "../../../assets/images/svgs/Stars";
import styles from "../auth.module.scss";

const Auth = () => {
  const hasToken = localStorage.getItem("access-token");
  const navigate = useNavigate();
  const theme = useTheme();

  const ButtonRef = useRef(null);
  const ProgressRef = useRef(null);

  const LogoRef = useRef(null);
  const PatternsRef = useRef(null);
  const CoinRef = useRef(null);
  const CoinCircle_1Ref = useRef(null);
  const CoinCircle_2Ref = useRef(null);
  const CoinCircle_3Ref = useRef(null);

  const SideShape_1Ref = useRef(null);
  const SideShape_2Ref = useRef(null);
  const SideShape_3Ref = useRef(null);

  const SmallCircle_1Ref = useRef(null);
  const SmallCircle_2Ref = useRef(null);

  const BIcon_1Ref = useRef(null);
  const BIcon_2Ref = useRef(null);

  const Stars_1Ref = useRef(null);
  const Stars_2Ref = useRef(null);
  const Stars_3Ref = useRef(null);
  const Stars_4Ref = useRef(null);
  const Stars_5Ref = useRef(null);

  const pageAnimationHandler = () => {
    //@ts-ignore
    ButtonRef.current.style.height = "5px";
    //@ts-ignore
    ButtonRef.current.style.color = "transparent";

    //@ts-ignore
    ProgressRef.current.style.left = 0;

    //@ts-ignore
    PatternsRef.current.style.opacity = 0;

    //@ts-ignore
    LogoRef.current.style.marginTop = "100px";
    //@ts-ignore
    LogoRef.current.style.opacity = 0;

    //@ts-ignore
    CoinRef.current.style.top = "calc(50% - 80px)";
    //@ts-ignore
    CoinRef.current.style.opacity = 1;

    //@ts-ignore
    CoinCircle_1Ref.current.style.transform = "rotate(-7deg)";
    //@ts-ignore
    CoinCircle_1Ref.current.style.opacity = 1;
    //@ts-ignore
    CoinCircle_2Ref.current.style.opacity = 1;
    //@ts-ignore
    CoinCircle_3Ref.current.style.transform = "rotate(7deg)";
    //@ts-ignore
    CoinCircle_3Ref.current.style.opacity = 1;

    //@ts-ignore
    SideShape_1Ref.current.classList.add(styles.sideShape_1_animation);
    //@ts-ignore
    SideShape_2Ref.current.classList.add(styles.sideShape_2_animation);
    //@ts-ignore
    SideShape_3Ref.current.classList.add(styles.sideShape_3_animation);

    //@ts-ignore
    BIcon_1Ref.current.classList.add(styles.bIcon_1_animation);
    //@ts-ignore
    BIcon_2Ref.current.classList.add(styles.bIcon_2_animation);

    //@ts-ignore
    SmallCircle_1Ref.current.style.opacity = 1;
    //@ts-ignore
    SmallCircle_2Ref.current.style.opacity = 1;

    //@ts-ignore
    Stars_1Ref.current.style.opacity = 1;
    //@ts-ignore
    Stars_1Ref.current.classList.add(styles.stars_1_animation);
    //@ts-ignore
    Stars_2Ref.current.style.opacity = 1;
    //@ts-ignore
    Stars_3Ref.current.style.opacity = 1;
    //@ts-ignore
    Stars_4Ref.current.style.opacity = 1;
    //@ts-ignore
    Stars_4Ref.current.classList.add(styles.stars_4_animation);
    //@ts-ignore
    Stars_5Ref.current.style.opacity = 1;
    //@ts-ignore
    Stars_5Ref.current.classList.add(styles.stars_1_animation);

    setTimeout(() => {
      navigate("/auth/login");
    }, 4000);
  };

  useEffect(() => {
    if (hasToken) {
      navigate("/");
    }
  }, []);

  return (
    <Box className={styles.authWrapper}>
      <Box className={styles.authLogo} ref={LogoRef}>
        <Logo />
      </Box>
      <Box ref={PatternsRef} sx={{ transition: "all .4s" }}>
        <Box className="pattern_1_auth"></Box>
        <Box className="pattern_2_auth"></Box>
        <Box className="pattern_3_auth"></Box>
        <Box className="pattern_4_auth"></Box>
      </Box>

      <Button
        ref={ButtonRef}
        onClick={pageAnimationHandler}
        variant="contained"
        className={styles.authButton}
        sx={{
          height: "55px",
          transition: "height .2s linear, color .2s",
          //@ts-ignore
          color: theme.palette.common.authButton,
        }}
      >
        <Box
          ref={ProgressRef}
          sx={{
            background:
              //@ts-ignore
              theme.palette.common.green,
          }}
        ></Box>
        START
      </Button>

      <img
        src={sideShape}
        className={styles.sideShape_1}
        ref={SideShape_1Ref}
        alt="icon"
      />
      <img
        src={sideShape}
        className={styles.sideShape_2}
        ref={SideShape_2Ref}
        alt="icon"
      />
      <img
        src={sideShape}
        className={styles.sideShape_3}
        ref={SideShape_3Ref}
        alt="icon"
      />

      <img src={bIcon} className={styles.bIcon_1} ref={BIcon_1Ref} alt="icon" />
      <img src={bIcon} className={styles.bIcon_2} ref={BIcon_2Ref} alt="icon" />

      <Box className={styles.smallCircle_1} ref={SmallCircle_1Ref}></Box>
      <Box className={styles.smallCircle_2} ref={SmallCircle_2Ref}></Box>

      <Box className={styles.mainCirclesLogo}>
        <Box className={styles.coinLogo} ref={CoinRef}>
          <img src={coinLogo} alt="icon" />
        </Box>
        <Box className={styles.circle_1} ref={CoinCircle_1Ref}></Box>
        <Box className={styles.circle_2} ref={CoinCircle_2Ref}></Box>
        <Box className={styles.circle_3} ref={CoinCircle_3Ref}></Box>

        <Box className={styles.stars_1} ref={Stars_1Ref}>
          <Stars />
        </Box>
        <Box className={styles.stars_2} ref={Stars_2Ref}>
          <Stars />
        </Box>
        <Box className={styles.stars_3} ref={Stars_3Ref}>
          <Stars />
        </Box>
        <Box className={styles.stars_4} ref={Stars_4Ref}>
          <Stars />
        </Box>
        <Box className={styles.stars_5} ref={Stars_5Ref}>
          <Stars />
        </Box>
      </Box>
    </Box>
  );
};

export default Auth;
