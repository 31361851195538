import { Box, Typography, useTheme } from "@mui/material";
import SubPagesLayout from "../../layout/subPagesLayout";
import CardTitle from "../../components/common/title/CardTitle";

interface TermType {
  id: number;
  title: string;
  items: {}[];
}

const TermsAndConditions = () => {
  const theme = useTheme();

  const termsAndConditionsItems = [
    {
      id: 1,
      title: "Acceptance of Terms",
      items: [
        "By using the Coinfident App, users agree to abide by the Terms and Conditions outlined herein.",
        "Users are encouraged to review these terms periodically, as they may be subject to updates or modifications.",
      ],
    },
    {
      id: 2,
      title: "Description of the App",
      items: [
        "The Coinfident App serves as a comprehensive tool for users interested in the cryptocurrency market.",
        "It offers real-time data on market sentiment, crypto news, influencers' opinions, and live cryptocurrency prices.",
        "Users should understand that the information presented is for informational purposes only and not intended as financial or investment advice.",
      ],
    },
    {
      id: 3,
      title: "User Eligibility",
      items: [
        "To use the Coinfident App, users must be at least 18 years old, ensuring they can legally enter into binding agreements.",
      ],
    },
    {
      id: 4,
      title: "Privacy",
      items: [
        "Coinfident prioritizes user privacy and implements robust security measures.",
        "Users are encouraged to review the Privacy Policy to understand how their data is collected, used, and protected.",
      ],
    },
    {
      id: 5,
      title: "User Responsibilities",
      items: [
        "Users are responsible for the accuracy and completeness of the information they provide within the app.",
        "They must also take precautions to maintain the security of their account credentials and report any unauthorized access promptly.",
        "Compliance with applicable laws and regulations is essential while using the app.",
      ],
    },
    {
      id: 6,
      title: "Disclaimer of Warranties",
      items: [
        "Coinfident provides information based on various sources but does not guarantee the accuracy, completeness, or reliability of the data.",
        "Users should be aware that all actions taken in the crypto market carry inherent risks.",
      ],
    },
    {
      id: 7,
      title: "Limitation of Liability",
      items: [
        "Coinfident shall not be held liable for any direct, indirect, incidental, or consequential damages arising from the use of the app.",
        "Users must exercise caution and conduct their research before making any financial decisions.",
      ],
    },
    {
      id: 8,
      title: "Intellectual Property",
      items: [
        "All intellectual property, including content, trademarks, and logos within the Coinfident App, are owned by Coinfident or its licensors.",
        "Users are prohibited from using, reproducing, distributing, or modifying any of this intellectual property without prior written consent.",
      ],
    },
    {
      id: 9,
      title: "Termination",
      items: [
        "Coinfident reserves the right to terminate or suspend users' access to the app without notice if it is believed that users have violated these Terms.",
      ],
    },
    {
      id: 10,
      title: "Governing Law",
      items: [
        "These Terms are governed by the laws of the specified jurisdiction, without regard to conflict of law principles.",
      ],
    },
    {
      id: 11,
      title: "Contact Information",
      items: [
        "Users are encouraged to reach out to Coinfident's support team via the provided email address for questions, concerns, or technical issues.",
      ],
    },
    {
      id: 12,
      title: "Account Creation and Usage",
      items: [
        "Users may create accounts on the Coinfident App to personalize their experience and access additional features.",
        "Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.",
        "Coinfident reserves the right to restrict or terminate accounts found in violation of these Terms.",
      ],
    },
    {
      id: 13,
      title: "Third-Party Links and Content",
      items: [
        "The Coinfident App may include links to third-party websites, content, or services for the convenience of users.",
        "Coinfident does not endorse or control these third-party resources and is not responsible for their content or practices.",
        "Users should review the terms and privacy policies of third-party websites before interacting with them.",
      ],
    },
    {
      id: 14,
      title: "Changes to the App",
      items: [
        "Coinfident may update, modify, or discontinue features or functionality of the app at any time without prior notice.",
        "Such changes may affect the user experience, but Coinfident will make efforts to provide notice of significant changes.",
      ],
    },
    {
      id: 15,
      title: "Feedback and Suggestions",
      items: [
        "Coinfident welcomes user feedback and suggestions regarding the app's features and functionality.",
        "By providing feedback, users grant Coinfident the right to use and implement their suggestions without any obligation.",
      ],
    },
    {
      id: 16,
      title: "Indemnification",
      items: [
        "Users agree to indemnify and hold Coinfident and its affiliates, officers, employees, and agents harmless from any claims, losses, damages, liabilities, costs, and expenses (including attorney's fees) arising from their use of the app or violation of these Terms.",
      ],
    },
    {
      id: 17,
      title: "Severability",
      items: [
        "If any provision of these Terms is found to be unenforceable or invalid by a court of competent jurisdiction, the remaining provisions shall continue to be valid and enforceable.",
      ],
    },
    {
      id: 18,
      title: "Waiver",
      items: [
        "Failure by Coinfident to enforce any right or provision of these Terms does not constitute a waiver of such rights or provisions.",
      ],
    },
    {
      id: 19,
      title: "Entire Agreement",
      items: [
        "These Terms and Conditions, along with the Privacy Policy, constitute the entire agreement between users and Coinfident regarding the use of the app and supersede all prior agreements and understandings.",
      ],
    },
    {
      id: 20,
      title: "Changes to Terms",
      items: [
        "Coinfident reserves the right to update these Terms and Conditions at any time. Users will be notified of significant changes, and continued use of the app after such changes constitutes acceptance of the revised Terms.",
      ],
    },
    {
      id: 21,
      title: "Use of Cookies",
      items: [
        "Coinfident may use cookies and similar tracking technologies to enhance user experience and gather information about user interactions with the app.",
        {
          title:
            "Cookies are small data files stored on the user's device. They may be used for various purposes, including but not limited to:",
          items: [
            "Providing a more personalized experience by remembering user preferences and settings.",
            "Analyzing user behavior and usage patterns to improve the app's functionality and content.",
            "Tracking performance metrics and helping diagnose technical issues.",
          ],
        },
        "Users can manage cookie preferences through their browser settings. By using the Coinfident App, users consent to the use of cookies in accordance with our Privacy Policy.",
        `For more information on how cookies are used and managed, please refer to our <a href='https://www.Coinfident.com/privacy-policy' target='_blank' style='color: ${theme.palette.warning.main}'>Privacy Policy</a>.`,
      ],
    },
  ];

  return (
    <SubPagesLayout title="Coinfident | Terms & Conditions">
      <Box mt={3}>
        <Box>
          <CardTitle isCenter>Terms & Conditions</CardTitle>
        </Box>
        <Box>
          {termsAndConditionsItems.map((term: TermType) => (
            <Box key={term.title}>
              <Typography
                variant="body1"
                color={theme.palette.text.primary}
                mt={6}
                sx={{
                  paddingBottom: ".3rem",
                  //@ts-ignore
                  borderBottom: `1px solid ${theme.palette.grey.light}`,
                  fontWeight: 300,
                }}
              >
                {term.title}
              </Typography>
              <ul
                style={{
                  paddingLeft: "1.2rem",
                  //@ts-ignore
                  color: theme.palette.common.whiteToGreen,
                }}
              >
                {term.items.map((listItem: any) =>
                  typeof listItem === "string" ? (
                    <li style={{ marginTop: ".8rem" }} key={listItem}>
                      <Typography
                        color={theme.palette.text.primary}
                        variant="caption"
                        sx={{
                          fontWeight: 100,
                          textAlign: "justify",
                          fontSize: ".8rem",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: listItem,
                        }}
                      ></Typography>
                    </li>
                  ) : (
                    <li style={{ marginTop: ".8rem" }} key={listItem}>
                      <Typography
                        color={theme.palette.text.primary}
                        variant="caption"
                        sx={{
                          fontWeight: 100,
                          textAlign: "justify",
                          fontSize: ".8rem",
                        }}
                      >
                        {listItem.title}
                      </Typography>
                      <ul
                        style={{
                          paddingLeft: "1.4rem",
                          //@ts-ignore
                          color: theme.palette.common.whiteToGreen,
                          listStyleType: "disc",
                        }}
                      >
                        {listItem.items.map((item: string) => (
                          <li style={{ marginTop: ".8rem" }} key={item}>
                            <Typography
                              color={theme.palette.text.primary}
                              variant="caption"
                              sx={{
                                fontWeight: 100,
                                textAlign: "justify",
                                fontSize: ".8rem",
                              }}
                            >
                              {item}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )
                )}
              </ul>
            </Box>
          ))}
        </Box>
      </Box>
    </SubPagesLayout>
  );
};

export default TermsAndConditions;
