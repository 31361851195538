import moment from "moment";

interface CoinsProps {
  cmc_tag: string;
  name: string;
  code: string;
  id: number;
}

interface CoinsStatisticsProps {
  coin: {
    cmc_tag: string;
    name: string;
    code: string;
    id: number;
  };
  latest_price: {
    price: number;
    market_cap: number;
    volume: number;
  };
  last_tweet_counts: number;
  tweet_count: number;
  prices: [];
  signal: {};
  top_influencers: string[];
}

export function getLinesCountOfAnElement(element: any) {
  const divHeight = element.offsetHeight;
  const lineHeight = 20;
  return Math.ceil(divHeight / lineHeight);
}

export function formatPrice(price: number) {
  if (price >= 1) {
    return Number(price).toFixed(3);
  } else {
    let decimalCount = 0;
    let tempNum = price;
    while (tempNum < 1 && tempNum !== 0) {
      tempNum *= 10;
      decimalCount++;
    }
    return Number(price).toFixed(decimalCount + 2);
  }
}

export function formatNumber(num: number) {
  if (num >= 1000000000) {
    return "$" + (num / 1000000000).toFixed(2) + "B";
  }
  if (num >= 1000000) {
    return "$" + (num / 1000000).toFixed(2) + "M";
  }
  return num;
}

export function calculateUnixTime(dateType: string) {
  const date = new Date();

  switch (dateType) {
    case "1H":
      return {
        start: moment(date).subtract(1, "hours").unix() * 1000,
        end: date.getTime(),
      };
    case "1D":
      return {
        start: moment(date).subtract(1, "days").unix() * 1000,
        end: date.getTime(),
      };
    case "1W":
      return {
        start: moment(date).subtract(7, "days").unix() * 1000,
        end: date.getTime(),
      };
    case "1M":
      return {
        start: moment(date).subtract(1, "months").unix() * 1000,
        end: date.getTime(),
      };
    case "1Y":
      return {
        start: moment(date).subtract(1, "years").unix() * 1000,
        end: date.getTime(),
      };
    case "All":
      return {
        start: moment(date).subtract(3, "years").unix() * 1000,
        end: date.getTime(),
      };
  }
}

export function calculateCoinAveragePrice(data: { priceUsd: string }[]) {
  let sum = 0;
  for (let i = 0; i < data?.length; i++) {
    sum += parseFloat(data[i]?.priceUsd);
  }

  const average = sum / data?.length;
  return average;
}

export function convertCoins(coinsArray: []) {
  //@ts-ignore
  if (coinsArray[0].coin) {
    const transformedArray = coinsArray
      .sort((a: any, b: any) => a.coin.rank - b.coin.rank)
      .map((obj: CoinsStatisticsProps) => ({
        id: obj.coin.cmc_tag,
        coin_id: obj.coin.id,
        full_id:
          obj.coin.cmc_tag === "bnb"
            ? "binance-coin"
            : obj.coin.cmc_tag === "polkadot-new"
            ? "polkadot"
            : obj.coin.cmc_tag.toLowerCase(),
        label: obj.coin.code,
        title: obj.coin.name,
        logo: obj.coin.code.toLowerCase(),
        prices: obj.prices,
        latest_price: obj.latest_price.price,
        market_cap: obj.latest_price.market_cap,
        volume: obj.latest_price.volume,
        tweet_count: obj.tweet_count,
        last_tweet_counts: obj.last_tweet_counts,
        signal: obj.signal,
        top_influencers: obj.top_influencers,
      }));
    return transformedArray;
  }

  const transformedArray = coinsArray
    .filter((item: any) => item.calc_model)
    .map((obj: CoinsProps) => ({
      id: obj.cmc_tag,
      coin_id: obj.id,
      full_id:
        obj.cmc_tag === "bnb"
          ? "binance-coin"
          : obj.cmc_tag === "polkadot-new"
          ? "polkadot"
          : obj.cmc_tag.toLowerCase(),
      label: obj.code,
      title: obj.name,
      logo: obj.code.toLowerCase(),
    }));
  return transformedArray;
}

export function calculateGuageValue(
  negative: number,
  positive: number,
  nullVlaue: number
) {
  const total = positive + negative + nullVlaue;

  if (positive === 0) {
    const value = (1 - negative / total) * 100;
    return Number(value.toFixed(0));
  } else {
    const value = (positive / total) * 100;
    return Number(value.toFixed(0));
  }
}

export function calculateWeekPercentChange(
  pricesArray: [],
  latest_price: number
) {
  const avgPrices = pricesArray.map((item: any) => item.avg_price);
  const firstAvgPrice = avgPrices.pop();
  const lastAvgPrice = avgPrices.shift();

  return avgPrices.length > 0
    ? ((lastAvgPrice - firstAvgPrice) / latest_price) * 100
    : 0;
}

export function calculateDayPercentChange(pricesArray: []) {
  //@ts-ignore
  const todayPrice = pricesArray[0].avg_price;
  //@ts-ignore
  const lastDayPrice = pricesArray[1].avg_price;

  const priceChange = todayPrice - lastDayPrice;
  const priceChangePercent = (priceChange / lastDayPrice) * 100;

  return priceChangePercent;
}

export function calculateCoinLineChartValue(
  currentValue: number,
  maxArrayValue: number
) {
  const minValue = 2;
  const maxValue = 100;

  const scaledValue =
    (currentValue / maxArrayValue) * (maxValue - minValue) + minValue;

  return Math.round(scaledValue);
}

export function getCoinImage(coinId: number) {
  return `https://pwa.coinfident.ai/i/api/rest/media/coin_imgs/${coinId}.png`;
}

export function tradingViewSymbol(symbol: string) {
  switch (symbol) {
    case "SHIB":
      return `COINBASE:SHIBUSD`;
    case "NFT":
      return "CRYPTO:NFTUSD";
    case "LUNC":
      return `BITMEX:BLUNC`;
    case "CSPR":
      return `CRYPTO:CSPRUSD`;
    case "KCS":
      return `PYTH:KCSUSD`;
    case "XAUT":
      return `BITFINEX:XAUTUSD`;
    case "XEC":
      return `BITFINEX:XECUSD`;
    case "USDP":
      return `BITTREX:USDPUSD`;
    case "BSV":
      return `POLONIEX:BCHSVUSD`;
    case "OKB":
      return `BITMEX:BOKB`;
    case "TUSD":
      return `COINBASE:TUSD`;
    case "LEO":
      return `BITFINEX:LEOUSD`;
    case "DAI":
      return `BITSTAMP:DAIUSD`;
    case "USDC":
      return `GEMINI:USDCUSD`;
    case "USDT":
      return `BITSTAMP:USDTUSD`;
    case "GT":
      return `PYTH:GTUSD`;
    case "BUSD":
      return `PYTH:BUSDUSD`;

    default:
      return `BINANCE:${symbol}USD`;
  }
}

export function selectedCoinsRegex(selectedCoinsArray: []) {
  const searchValue = selectedCoinsArray
    .map((coin: any) => `search=${coin}`)
    .join("&");

  return searchValue;
}

export function sortTableData(columnName: string) {
  var selectedColumn = "";

  switch (columnName) {
    case "assets":
      selectedColumn = "label";
      break;
    case "price":
      selectedColumn = "latest_price";
      break;
    case "buy score (86%)":
      selectedColumn = "signal";
      break;
    case "posts":
      selectedColumn = "tweet_count";
      break;
    case "posts change rate":
      selectedColumn = "last_tweet_counts";
      break;
    case "24h volume":
      selectedColumn = "volume";
      break;
    case "market cap":
      selectedColumn = "market_cap";
      break;
    default:
      selectedColumn = columnName;
  }

  return selectedColumn;
}

export function calculateDate(type: string) {
  let end_date = "";

  switch (type) {
    case "1W":
      {
        end_date = moment().subtract(7, "days").format("YYYY-MM-DD");
      }
      break;
    case "1M":
      {
        end_date = moment().subtract(1, "months").format("YYYY-MM-DD");
      }
      break;
    case "1Y":
      {
        end_date = moment().subtract(12, "months").format("YYYY-MM-DD");
      }
      break;
    case "All":
      {
        end_date = moment().subtract(24, "months").format("YYYY-MM-DD");
      }
      break;
    default:
      {
        end_date = moment().subtract(24, "days").format("YYYY-MM-DD");
      }
      break;
  }

  return end_date;
}
