import { useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { tradingViewSymbol } from "../../../utils/functions";

//@ts-ignore
let tvScriptLoadingPromise;

const FinancialChart: React.FC<{ symbol: string }> = ({ symbol }) => {
  const onLoadScriptRef = useRef();
  const theme = useTheme();

  useEffect(
    //@ts-ignore
    () => {
      //@ts-ignore
      onLoadScriptRef.current = createWidget;

      //@ts-ignore
      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement("script");
          script.id = "tradingview-widget-loading-script";
          script.src = "https://s3.tradingview.com/tv.js";
          script.type = "text/javascript";
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(
        //@ts-ignore
        () => onLoadScriptRef.current && onLoadScriptRef.current()
      );

      //@ts-ignore
      return () => (onLoadScriptRef.current = null);

      function createWidget() {
        if (
          document.getElementById("financial_chart") &&
          "TradingView" in window
        ) {
          //@ts-ignore
          new window.TradingView.widget({
            width: "100%",
            height: 420,
            symbol: tradingViewSymbol(symbol),
            interval: "D",
            timezone: "Etc/UTC",
            theme: theme.palette.mode === "light" ? "light" : "dark",
            style: "8",
            locale: "in",
            enable_publishing: false,
            withdateranges: true,
            save_image: false,
            details: false,
            container_id: "financial_chart",
          });
        }
      }
    },
    []
  );

  return (
    <div className="tradingview-widget-container">
      <div id="financial_chart" />
    </div>
  );
};

export default FinancialChart;
