import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material";
import styles from "./buttons.module.scss";

interface PrimaryButtonProps {
  text: string;
  size?: string;
  linkUrl?: string;
  disabled?: boolean;
  loading?: boolean;
  isFullWidth?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  linkUrl,
  disabled,
  loading,
  isFullWidth,
  size,
}) => {
  const theme = useTheme();

  if (linkUrl) {
    return (
      <Link
        to={linkUrl}
        className={styles.primary_button}
        style={{
          //@ts-ignore
          color: theme.palette.common.greenToWhite,
          background: theme.palette.secondary.main,
        }}
      >
        {text}
      </Link>
    );
  }
  return (
    <LoadingButton
      fullWidth={isFullWidth ? true : false}
      type="submit"
      variant="contained"
      loading={loading}
      disabled={disabled}
      loadingPosition="center"
      className={styles.primary_button}
      //@ts-ignore
      size={size ? size : "medium"}
      sx={{
        //@ts-ignore
        color: theme.palette.common.greenToWhite,
        background: theme.palette.secondary.main,
      }}
    >
      {text}
    </LoadingButton>
  );
};

export default PrimaryButton;
