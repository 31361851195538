import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FaCoins } from "react-icons/fa";
import { PiHouseFill } from "react-icons/pi";
import { BsFillFileTextFill } from "react-icons/bs";
import { HiMiniUserGroup } from "react-icons/hi2";
import styles from "../layout.module.scss";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  const [value, setValue] = useState(
    location.pathname === "/"
      ? 0
      : location.pathname === "/share-of-voice"
      ? 1
      : location.pathname === "/news"
      ? 2
      : location.pathname === "/influencers"
      ? 3
      : 4
  );

  if (isSmallScreen) {
    return (
      <Box
        className={`${styles.footerWrapper} glass-dark-background`}
        //@ts-ignore
        sx={{ background: theme.palette.common.footer }}
      >
        <BottomNavigation
          showLabels={false}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className={styles.bottomNavigation}
          sx={{
            "& .Mui-selected": {
              //@ts-ignore
              background: theme.palette.common.selectedFooterItem,
            },
            "& .MuiBottomNavigationAction-label": {
              height: "15px",
              marginTop: "6px",
              fontSize: ".63rem !important",
              fontWeight: 100,
              width: "50px",
              lineHeight: 1,
              opacity: 1,
              transition: "all .3s",
            },
            "& .Mui-selected .MuiBottomNavigationAction-label": {
              marginTop: "7px",
              transform: "translateY(0px)",
              background: "transparent",
            },
          }}
        >
          <BottomNavigationAction
            icon={<PiHouseFill size={24} />}
            label="Home"
            onClick={() => navigate("/")}
            className={styles.bottomNavigationAction}
            sx={{
              color:
                location.pathname === "/"
                  ? //@ts-ignore
                    `${theme.palette.primary.main} !important`
                  : `${theme.palette.text.primary} !important`,
            }}
          />
          <BottomNavigationAction
            icon={<FaCoins size={24} />}
            label="Share of voice"
            onClick={() => navigate("/share-of-voice")}
            className={styles.bottomNavigationAction}
            sx={{
              color:
                location.pathname === "/share-of-voice"
                  ? //@ts-ignore
                    `${theme.palette.primary.main} !important`
                  : `${theme.palette.text.primary} !important`,
            }}
          />
          <BottomNavigationAction
            icon={<BsFillFileTextFill size={24} />}
            label="News"
            onClick={() => navigate("/news")}
            className={styles.bottomNavigationAction}
            sx={{
              color:
                location.pathname === "/news"
                  ? //@ts-ignore
                    `${theme.palette.primary.main} !important`
                  : `${theme.palette.text.primary} !important`,
            }}
          />
          <BottomNavigationAction
            icon={<HiMiniUserGroup size={24} />}
            label="Influencers"
            onClick={() => navigate("/influencers")}
            className={styles.bottomNavigationAction}
            sx={{
              color:
                location.pathname === "/influencers"
                  ? //@ts-ignore
                    `${theme.palette.primary.main} !important`
                  : `${theme.palette.text.primary} !important`,
            }}
          />
        </BottomNavigation>
      </Box>
    );
  } else {
    return null;
  }
}
