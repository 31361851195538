import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { RiEyeLine, RiEyeOffFill } from "react-icons/ri";

const Input: React.FC<{
  label: string;
  type?: string;
  formik: any;
  name: string;
  helpText?: string;
  hasInlineLabel?: boolean;
  hasInnerLabel?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
}> = ({
  label,
  type = "text",
  formik,
  name,
  helpText,
  hasInlineLabel = true,
  hasInnerLabel = false,
  disabled = false,
  icon,
}) => {
  const theme = useTheme();
  const [show, setShow] = useState(false);

  return (
    <Grid container>
      {hasInlineLabel ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: { xs: ".4rem !important" },
          }}
        >
          {icon ? icon : ""}
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 100,
            }}
          >
            {label}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          label={hasInnerLabel ? label : ""}
          variant="standard"
          type={show ? "text" : type}
          {...formik.getFieldProps(name)}
          name={name}
          size="small"
          disabled={disabled}
          sx={{ fontWeight: 100 }}
          InputProps={
            type === "password"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {show ? (
                        <RiEyeLine
                          onClick={() => setShow(!show)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <RiEyeOffFill
                          onClick={() => setShow(!show)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
        {helpText && (
          <Typography
            variant="caption"
            sx={{
              fontWeight: 100,
              marginTop: "7px",
              display: "block",
              //@ts-ignore
              color: theme.palette.common.mute,
            }}
          >
            {helpText}
          </Typography>
        )}
        {formik.touched[name] && formik.errors[name] && (
          <Typography
            sx={{
              marginTop: 0.5,
              fontSize: ".85rem",
              color: theme.palette.error.main,
            }}
          >
            {formik.errors[name]}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Input;
