import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { BiSolidHot, BiSort } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import styles from "../newsHeader.module.scss";

interface SortListProps {
  twitterFilters: {
    ordering: string;
  };
  setTwitterFilters: React.Dispatch<React.SetStateAction<{}>>;
}

const SortList: React.FC<SortListProps> = ({
  twitterFilters,
  setTwitterFilters,
}) => {
  const theme = useTheme();

  return (
    <Box
      className={styles.sortingFilter}
      sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }}
    >
      <Typography variant="h6">
        <BiSort size={18} color={theme.palette.text.primary} />
      </Typography>
      <Box>
        <IconButton
          size="small"
          sx={{
            backgroundColor:
              twitterFilters.ordering === "-total"
                ? `${theme.palette.primary.main} !important`
                : "transparent",
          }}
          onClick={() =>
            setTwitterFilters({ ...twitterFilters, ordering: "-total" })
          }
        >
          <BiSolidHot size={21} />
          <Typography
            variant="caption"
            ml={0.5}
            sx={{ fontWeight: 100 }}
            color={
              //@ts-ignore
              theme.palette.common.whiteToDarkGreen
            }
          >
            Top Tweets
          </Typography>
        </IconButton>
        <IconButton
          size="small"
          sx={{
            backgroundColor:
              twitterFilters.ordering === "-created_at"
                ? `${theme.palette.primary.main} !important`
                : "transparent",
          }}
          onClick={() =>
            setTwitterFilters({ ...twitterFilters, ordering: "-created_at" })
          }
        >
          <AiOutlineClockCircle size={21} />
          <Typography
            variant="caption"
            ml={0.5}
            sx={{ fontWeight: 100 }}
            color={
              //@ts-ignore
              theme.palette.common.whiteToDarkGreen
            }
          >
            Latest Tweets
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default SortList;
