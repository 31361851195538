import LineChart from "../../charts/LineChart";

interface AccountStatisticsChartProps {
  data: {
    date: string;
    tweet_cnt: number;
  }[];
}

const AccountStatisticsChart: React.FC<AccountStatisticsChartProps> = ({
  data,
}) => {
  let accountStatisticsChartData = [...data].reverse();

  return (
    <LineChart
      name="Posts"
      data={accountStatisticsChartData.slice(-7).map(({ date, tweet_cnt }) => ({
        x: date,
        y: tweet_cnt,
      }))}
    />
  );
};

export default AccountStatisticsChart;
