import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { checkAuth, setAuthToken } from "../../services/auth";
import {
  calculateInsidePatternsPosition,
  calculatePatternsPosition,
} from "../../utils/patternsPosition";
import { getUserData } from "../../api/auth";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  setIsAuthenticated,
  setUserData,
} from "../../features/auth/accountSlice";
import Shape from "../../assets/images/icons/side_shape.svg";

const AppLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useAppDispatch();
  const accessToken = localStorage.getItem("access-token");
  const refreshToken = localStorage.getItem("refresh-token");
  const userInfo = useSelector((state: RootState) => state.account);

  const [targetPosition, setTargetPosition] = useState<{
    pattern_1: string[];
    pattern_2: string[];
  }>({
    pattern_1: ["26px", "85%"],
    pattern_2: ["275px", "-130px"],
  });

  const [insideShapesPosition, setInsideShapesPosition] = useState<{
    pattern_3: string[];
    pattern_4: string[];
    pattern_5: string[];
    pattern_6: string[];
  }>({
    pattern_3: ["60%", "130%", "0"],
    pattern_4: ["-10%", "100%"],
    pattern_5: ["-10%", "100%"],
    pattern_6: ["100%", "-30%", "90"],
  });

  useEffect(() => {
    if (accessToken) {
      //@ts-ignore
      setAuthToken(accessToken, refreshToken);
      checkAuth().then((isAuthenticated) => {
        dispatch(setIsAuthenticated(true));
        if (!userInfo.data) {
          getUserData()
            .then((response) => {
              const userData = {
                access: accessToken,
                refresh: refreshToken,
                user: response.data,
              };
              //@ts-ignore
              dispatch(setUserData(userData));
            })
            .catch((err) => {});
        }
      });
    } else {
      navigate("/auth");
    }
  }, []);

  useEffect(() => {
    setTargetPosition(calculatePatternsPosition(location.pathname));
    setInsideShapesPosition(calculateInsidePatternsPosition(location.pathname));
  }, [location]);

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: "1rem",
        boxSizing: "border-box",
        transition: "all .3s",
      }}
    >
      {location.pathname !== "/auth" && (
        <>
          <Box
            className="pattern_1"
            sx={{
              top: targetPosition.pattern_1[0],
              left: targetPosition.pattern_1[1],
            }}
          ></Box>
          <Box
            className="pattern_2"
            sx={{
              top: targetPosition.pattern_2[0],
              right: targetPosition.pattern_2[1],
            }}
          ></Box>
          <img
            src={Shape}
            className="pattern_3"
            alt="icon"
            style={{
              top: insideShapesPosition.pattern_3[0],
              left: insideShapesPosition.pattern_3[1],
              transform: `rotate(${insideShapesPosition.pattern_3[2]}deg)`,
            }}
          />
          <Box
            className="pattern_4"
            sx={{
              top: insideShapesPosition.pattern_4[0],
              right: insideShapesPosition.pattern_4[1],
            }}
          ></Box>
          <Box
            className="pattern_5"
            sx={{
              top: insideShapesPosition.pattern_5[0],
              right: insideShapesPosition.pattern_5[1],
            }}
          ></Box>
          <Box
            className="pattern_6"
            sx={{
              top: insideShapesPosition.pattern_6[0],
              right: insideShapesPosition.pattern_6[1],
              transform: `rotate(${insideShapesPosition.pattern_6[2]}deg)`,
            }}
          ></Box>
        </>
      )}

      <Box sx={{ position: "relative", zIndex: 100 }}>{children}</Box>
    </Box>
  );
};

export default AppLayout;
