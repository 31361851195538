import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import AuthTitle from "../../../components/common/title/AuthTitle";
import Logo from "../../../components/common/logo";
import Input from "../../../components/form/Input";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import {
  setIsAuthenticated,
  setUserData,
} from "../../../features/auth/accountSlice";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import { loginWithEmail } from "../../../api/auth";
import styles from "../auth.module.scss";

interface LoginState {
  email: string;
  password: string;
}

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const hasToken = localStorage.getItem("access-token");

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<LoginState>({
    email: "",
    password: "",
  });

  const getSubmitHandler = () => async (values: LoginState, formik: any) => {
    setLoading(true);
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    const response = await loginWithEmail(values);
    if (response) {
      setLoading(false);
      if (response.status === 200) {
        if (response?.data?.access) {
          dispatch(setUserData(response.data));
          dispatch(setIsAuthenticated(true));
          navigate("/");
        }
      } else {
        toast.error("Invalid login data");
        setLoading(false);
        formik.resetForm();
      }
    }
  };

  useEffect(() => {
    if (hasToken) {
      navigate("/");
    }
  }, []);

  return (
    <Box className={styles.loginWrapper}>
      <Logo isAuth />
      <Fade>
        <AuthTitle>Login</AuthTitle>
        <Formik
          initialValues={initialValues}
          validateOnMount={true}
          validationSchema={useCreateSchema(["email", "password"])}
          enableReinitialize={true}
          onSubmit={getSubmitHandler()}
        >
          {(formik) => (
            <Form id="login_form">
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={7}>
                  <Input
                    name="email"
                    formik={formik}
                    label="Email"
                    hasInlineLabel={true}
                    hasInnerLabel={false}
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Input
                    name="password"
                    formik={formik}
                    type="password"
                    label="Password"
                    hasInlineLabel={true}
                    hasInnerLabel={false}
                  />
                  <Box sx={{ margin: ".2rem 0 1.5rem" }}>
                    <Link
                      to="/auth/reset-password"
                      style={{
                        fontSize: ".8rem",
                        fontWeight: 100,
                        //@ts-ignore
                        color: theme.palette.common.greenText,
                      }}
                    >
                      Forget Password?
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <PrimaryButton
                    text="Login"
                    disabled={!formik.isValid}
                    loading={loading}
                    isFullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box className={styles.linkWrapper}>
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      Don’t have an account yet?
                    </Typography>
                    <Link
                      to="/auth/sign-up"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Sign Up
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fade>
    </Box>
  );
};

export default Login;
