import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Tab, Tabs } from "@mui/material";
import SubPagesLayout from "../../layout/subPagesLayout";
import Loading from "../../components/common/loading";
import ProfileData from "../../components/profile/data";
import PasswordChange from "../../components/profile/passwordChange";
import Transactions from "../../components/profile/transactions";
import CardTitle from "../../components/common/title/CardTitle";
import { RootState } from "../../features/store";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Profile = () => {
  const userInfo = useSelector((state: RootState) => state.account);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `profile-tab-${index}`,
      "aria-controls": `profile-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ px: 2, mt: 5 }}>{children}</Box>}
      </Box>
    );
  }

  return (
    <SubPagesLayout title="Coinfident | Profile">
      {!userInfo.data ? (
        <Loading />
      ) : (
        <Box
          mt={2}
          sx={{ margin: "auto", marginTop: "1.5rem", maxWidth: "500px" }}
        >
          <CardTitle isCenter>Profile</CardTitle>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="profile tabs"
            sx={{ marginTop: "1.5rem" }}
          >
            <Tab
              label="Profile"
              {...a11yProps(0)}
              sx={{ fontWeight: 100, flex: 1, textTransform: "none" }}
            />
            <Tab
              label="Password"
              {...a11yProps(1)}
              sx={{ fontWeight: 100, flex: 1, textTransform: "none" }}
            />
            <Tab
              label="Transactions"
              {...a11yProps(2)}
              sx={{ fontWeight: 100, flex: 1, textTransform: "none" }}
            />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <ProfileData
              //@ts-ignore
              data={userInfo}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <PasswordChange />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Transactions />
          </CustomTabPanel>
        </Box>
      )}
    </SubPagesLayout>
  );
};

export default Profile;
