import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";

export interface SignalState {
  newsPageData: null | {
    count: number;
    next: string | null;
    results: {
      relevant_tweets: [];
      signal: {
        0: number;
        1: number;
        null: number;
      };
      sentiment: {
        0: number;
        1: number;
        2: number;
      };
    };

    tab: string;
    coin: number;
  };
  influencerSignal: null | {};
  influencerSignalLoading: boolean;
  error: string | null;
}

export const fetchInfluencerSignal = createAsyncThunk<
  { data: null },
  { coin: number; page: number; category: string },
  { rejectValue: any }
>("influencer signal", async (requestProps, thunkAPI) => {
  try {
    const response = await http.post(
      `/crypto/get-influencer-signal/?page=${requestProps.page}${
        requestProps.category ? `&category=${requestProps.category}` : ""
      }`,
      {
        coin_id: requestProps.coin,
      }
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: SignalState = {
  newsPageData: null,
  influencerSignal: null,
  influencerSignalLoading: false,
  error: null,
};

export const signalSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    setNewsPageData: (state, action) => {
      state.newsPageData = {
        count: action.payload.count,
        next: action.payload.next,
        results: action.payload.results,
        tab: action.payload.tab,
        coin: action.payload.coin,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerSignal.pending, (state) => {
        state.influencerSignalLoading = true;
        state.error = null;
      })
      .addCase(fetchInfluencerSignal.fulfilled, (state, action) => {
        state.influencerSignalLoading = false;
        state.influencerSignal = action.payload;
      })
      .addCase(fetchInfluencerSignal.rejected, (state, action) => {
        state.influencerSignalLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default signalSlice.reducer;

export const { setNewsPageData } = signalSlice.actions;
