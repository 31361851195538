import Auth from "../pages/auth/auth";
import Login from "../pages/auth/login";
import ResetPassword from "../pages/auth/resetPassword";
import SignUp from "../pages/auth/signUp";
import ResendVerificationEmail from "../pages/auth/resendVerificationEmail";
import Home from "../pages/home";
import Account from "../pages/account";
import Profile from "../pages/profile";
import Settings from "../pages/settings";
import Subscription from "../pages/subscription";
import News from "../pages/news";
import Crypto from "../pages/crypto";
import ShareOfVoice from "../pages/shareOfVoice";
import Influencers from "../pages/influencers";
import Influencer from "../pages/influencer";
import NotFound from "../pages/notFound";
import FAQ from "../pages/faq";
import TermsAndConditions from "../pages/termsAndConditions";
import AccountManagement from "../pages/accountManagement";
import MarketcapWeighted from "../pages/marketcapWeighted";
import BotDetection from "../pages/botDetection";

export const authRoutes = [
  { pathName: "/auth", element: <Auth /> },
  { pathName: "/auth/login", element: <Login /> },
  { pathName: "/auth/reset-password", element: <ResetPassword /> },
  { pathName: "/auth/sign-up", element: <SignUp /> },
  {
    pathName: "/auth/resend-verification-email",
    element: <ResendVerificationEmail />,
  },
];

export const mainRoutes = [
  { pathName: "/account", element: <Account /> },
  { pathName: "/account/settings", element: <Settings /> },
  // { pathName: "/account/subscription", element: <Subscription /> },
  { pathName: "/account/profile", element: <Profile /> },
  { pathName: "/account/management", element: <AccountManagement /> },
  { pathName: "/account/FAQ", element: <FAQ /> },
  {
    pathName: "/account/terms-and-conditions",
    element: <TermsAndConditions />,
  },

  { pathName: "/news", element: <News /> },
  { pathName: "/influencers", element: <Influencers /> },
  { pathName: "/influencers/:id", element: <Influencer /> },
  { pathName: "/share-of-voice", element: <ShareOfVoice /> },
  { pathName: "/crypto-index", element: <MarketcapWeighted /> },
  { pathName: "/bot-detection", element: <BotDetection /> },

  { pathName: "/prices/:crypto", element: <Crypto /> },
  { pathName: "/", element: <Home /> },
  { pathName: "*", element: <NotFound /> },
];
