import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { convertCoins } from "../../utils/functions";

export interface CryptoResponseState {
  coinStatisticsState: null | {};
  availableCoins: null | {};
  coinsStatistics: null | {};
  marketcapWeighted: null | [];
  marketcapWeightedLoading: boolean;
  availableCoinsLoading: boolean;
  coinsStatisticsLoading: boolean;
  error: string | null;
}

export const fetchAvailableCoins = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("available coins", async (_, thunkAPI) => {
  try {
    const url = "/crypto/get-coin-list/";
    const response = await http.get(url);
    const data = convertCoins(response.data.response.data);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCoinsStatistics = createAsyncThunk<
  any,
  number,
  { rejectValue: any }
>("coins statistics", async (pageNumber, thunkAPI) => {
  try {
    const url = `/crypto/get-coins-statistics/?page_size=100`;
    const response = await http.get(url);
    //@ts-ignore
    const data = convertCoins(Object.values(response.data.response.data));
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCoinsMarketcapWeighted = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("coins marketcap weighted", async (_, thunkAPI) => {
  try {
    const url = `/crypto/get-innovative-parameter/`;
    const response = await http.get(url);
    //@ts-ignore
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptoResponseState = {
  coinStatisticsState: {
    page: 1,
    result: [],
  },
  coinsStatistics: null,
  availableCoins: null,
  marketcapWeighted: null,
  marketcapWeightedLoading: true,
  coinsStatisticsLoading: true,
  availableCoinsLoading: false,
  error: null,
};

export const cryptosSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    setCoinStatistics: (state, action) => {
      state.coinStatisticsState = {
        page: action.payload.page,
        result: action.payload.result,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableCoins.pending, (state) => {
        state.availableCoinsLoading = true;
        state.error = null;
      })
      .addCase(fetchAvailableCoins.fulfilled, (state, action) => {
        state.availableCoinsLoading = false;
        state.availableCoins = action.payload;
      })
      .addCase(fetchAvailableCoins.rejected, (state, action) => {
        state.availableCoinsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchCoinsStatistics.pending, (state) => {
        state.coinsStatisticsLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinsStatistics.fulfilled, (state, action) => {
        state.coinsStatisticsLoading = false;
        state.coinsStatistics = action.payload;
      })
      .addCase(fetchCoinsStatistics.rejected, (state, action) => {
        state.coinsStatisticsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchCoinsMarketcapWeighted.pending, (state) => {
        state.marketcapWeightedLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinsMarketcapWeighted.fulfilled, (state, action) => {
        state.marketcapWeightedLoading = false;
        state.marketcapWeighted = action.payload;
      })
      .addCase(fetchCoinsMarketcapWeighted.rejected, (state, action) => {
        state.marketcapWeightedLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default cryptosSlice.reducer;

export const { setCoinStatistics } = cryptosSlice.actions;
