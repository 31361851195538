import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface SingleAssetHistoryType {
  name: string | undefined;
  interval: string;
  start: number | undefined;
  end: number | undefined;
}

export interface CryptoResponseState {
  coin: null | {};
  coinHistory: null | {};
  tweetsCountResult: null | [];
  marketcapWeightedResult: null | [];
  coinLoading: boolean;
  coinHistoryLoading: boolean;
  error: string | null;
}

export const fetchSingleAsset = createAsyncThunk<
  { data: null },
  string | undefined,
  { rejectValue: any }
>("asset", async (name, thunkAPI) => {
  try {
    const response = await axios.get(
      `https://api.coincap.io/v2/assets/${name}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSingleAssetHistory = createAsyncThunk<
  { data: null },
  SingleAssetHistoryType,
  { rejectValue: any }
>("asset-history", async (requestData, thunkAPI) => {
  try {
    const response = await axios.get(
      `https://api.coincap.io/v2/assets/${requestData.name}/history?interval=${requestData.interval}&start=${requestData.start}&end=${requestData.end}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptoResponseState = {
  coin: null,
  coinHistory: null,
  marketcapWeightedResult: null,
  tweetsCountResult: null,
  coinLoading: false,
  coinHistoryLoading: false,
  error: null,
};

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    setTweetsCountResult: (state, action) => {
      state.tweetsCountResult = action.payload;
    },
    setMarketcapWeightedResult: (state, action) => {
      state.marketcapWeightedResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleAsset.pending, (state) => {
        state.coinLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAsset.fulfilled, (state, action) => {
        state.coinLoading = false;
        state.coin = action.payload;
      })
      .addCase(fetchSingleAsset.rejected, (state, action) => {
        state.coinLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchSingleAssetHistory.pending, (state) => {
        state.coinHistoryLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAssetHistory.fulfilled, (state, action) => {
        state.coinHistoryLoading = false;
        state.coinHistory = action.payload;
      })
      .addCase(fetchSingleAssetHistory.rejected, (state, action) => {
        state.coinHistoryLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default cryptoSlice.reducer;

export const { setTweetsCountResult, setMarketcapWeightedResult } =
  cryptoSlice.actions;
