import { Box } from "@mui/material";
import TabSelector from "./tabSelector";
import styles from "./cryptoSignalHeader.module.scss";

interface SelectedCommunityType {
  tab: string;
  coin: number;
}

interface CryptoSignalHeaderProps {
  loading: boolean;
  selectedCommunity: SelectedCommunityType;
  fetchNewsDataHandler: Function;
  setSelectedCommunity: React.Dispatch<
    React.SetStateAction<SelectedCommunityType>
  >;
}

const CryptoSignalHeader: React.FC<CryptoSignalHeaderProps> = ({
  loading,
  selectedCommunity,
  setSelectedCommunity,
  fetchNewsDataHandler,
}) => {
  const tabChangeHandler = (data: string) => {
    setSelectedCommunity({
      tab: data,
      coin: selectedCommunity.coin,
    });
    fetchNewsDataHandler(data, selectedCommunity.coin);
  };

  return (
    <>
      <Box
        className={styles.cryptoSignalHeaderWrapper}
        sx={{ opacity: loading ? 0.7 : 1 }}
      >
        {loading && <Box className={styles.cryptoSignalLoadingWrapper}></Box>}
        <TabSelector
          value={selectedCommunity}
          handleChange={tabChangeHandler}
        />
      </Box>
    </>
  );
};

export default CryptoSignalHeader;
