import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useTheme } from "@mui/material";

const MarketcapTreemapChart: React.FC<{
  data: { x: string | number; y: number }[];
  name: string;
}> = ({ data, name }) => {
  const theme = useTheme();

  const [state, setState] = useState<{
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    options: object;
  }>({
    series: [],
    options: {},
  });

  function removeDuplicates(array: []) {
    return array.filter((item: { coin: number }, index) => {
      const firstIndex = array.findIndex(
        (element: { coin: number }) => element.coin === item.coin
      );
      return index === firstIndex;
    });
  }

  function getMinMaxAvgMarketCap(array: any) {
    const uniqueArray = removeDuplicates(array);
    let highestAvgMarketCap = 0;
    let lowestAvgMarketCap = Infinity;
    uniqueArray.forEach((item: { avg_maket_cap: number }) => {
      if (item.avg_maket_cap > highestAvgMarketCap)
        highestAvgMarketCap = item.avg_maket_cap;

      if (item.avg_maket_cap < lowestAvgMarketCap)
        lowestAvgMarketCap = item.avg_maket_cap;
    });
    return { highest: highestAvgMarketCap, lowest: lowestAvgMarketCap };
  }

  useEffect(() => {
    setState({
      series: [
        {
          name,
          //@ts-ignore
          data: removeDuplicates(data).map(({ code, avg_maket_cap }) => ({
            x: `${code}`,
            y: avg_maket_cap,
          })),
        },
      ],
      options: {
        chart: {
          parentHeightOffset: 0,
          type: "treemap",
          fontFamily: "Poppins",
          toolbar: { show: false },
          zoom: { enabled: false },
          animations: {
            easing: "easeout",
          },
        },

        tooltip: {
          y: {
            formatter: function (avg: number) {
              if (typeof avg !== "undefined") {
                return avg.toFixed(2) + "%";
              }
              return avg;
            },
          },
        },

        legend: {
          show: false,
        },
        plotOptions: {
          treemap: {
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: getMinMaxAvgMarketCap(data).lowest,
                  to: getMinMaxAvgMarketCap(data).highest,
                  color:
                    //@ts-ignore
                    theme.palette.common.authButton,
                },
              ],
            },
          },
        },
      },
    });
  }, [data]);

  return (
    <Box className="marketcap_treemap">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="treemap"
        height="800px"
      />
    </Box>
  );
};

export default MarketcapTreemapChart;
