import { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineRssFeed } from "react-icons/md";

interface ChangeFeedProps {
  setNewsType: React.Dispatch<React.SetStateAction<string>>;
}

const ChangeFeed: React.FC<ChangeFeedProps> = ({ setNewsType }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeTabHandler = (tabName: string) => {
    setNewsType(tabName);
    setAnchorEl(null);
  };

  const options = [
    {
      title: "Twitter",
      tabName: "Twitter News",
      icon: <FaXTwitter size={22} color={theme.palette.text.primary} />,
    },
    {
      title: "Rss",
      tabName: "News websites",
      icon: <MdOutlineRssFeed size={22} color={theme.palette.text.primary} />,
    },
  ];

  const ITEM_HEIGHT = 30;

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="feed-button"
        aria-controls={open ? "feed-tab" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MdOutlineRssFeed size={21} />
      </IconButton>
      <Menu
        id="feed-tab"
        MenuListProps={{
          "aria-labelledby": "feed-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "13.5ch",
            borderRadius: "20px",
            background:
              //@ts-ignore
              theme.palette.common.popup,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.title}
            onClick={() => changeTabHandler(option.title)}
            sx={{ minHeight: "30px", padding: "0 .5rem" }}
          >
            {option.icon}
            <Typography
              variant="caption"
              ml={1}
              align="left"
              sx={{
                color: theme.palette.text.primary,
                fontWeight: 100,
                width: "100%",
              }}
            >
              {option.tabName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ChangeFeed;
