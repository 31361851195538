import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { TiChartBar } from "react-icons/ti";
import { TbChartDonut4 } from "react-icons/tb";
import { MdOutlineShowChart } from "react-icons/md";
import { formatNumber, formatPrice } from "../../../utils/functions";
import FullPageBlur from "../../common/fullPageBlur";
import BottomDrawer from "../../common/bottomDrawer";
import styles from "./cryptoInfo.module.scss";

interface CryptoDataProps {
  data: {
    data: {
      marketCapUsd: string;
      volumeUsd24Hr: string;
      supply: number;
      maxSupply: number;
      vwap24Hr: number;
      changePercent24Hr: number;
    };
  };
}

interface cryptoItemProps {
  id: number;
  icon: JSX.Element;
  title: string;
  popupTitle: string;
  popupContent: string;
  value: string;
}

const CryptoInfo: React.FC<CryptoDataProps> = ({ data }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [popupContent, setPopupContent] = useState({
    infoTitle: "",
    info: "",
  });

  const cryptoInfoData = [
    {
      id: 1,
      title: "24h %",
      icon: <MdOutlineShowChart size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "24h %",
      popupContent: "The direction and value change in the last 24 hours",
      value: `${Number(data?.data.changePercent24Hr).toFixed(2)}%`,
    },
    {
      id: 2,
      title: "Market cap",
      icon: <MdOutlineShowChart size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "Market cap",
      popupContent:
        "The total market value of a cryptocurrency's circulating supply.",
      value: `${formatNumber(Number(data?.data.marketCapUsd))}`,
    },
    {
      id: 3,
      title: "Volume",
      icon: <TiChartBar size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "Volume",
      popupContent:
        "A measure of how much of a cryptocurrency was traded in the last 24 hours.",
      value: `${formatNumber(Number(data?.data.volumeUsd24Hr))}`,
    },
    {
      id: 4,
      title: "Supply",
      icon: <TbChartDonut4 size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "Supply",
      popupContent:
        "The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.",
      value: `${formatNumber(Number(data?.data.supply))}`,
    },
    {
      id: 5,
      title: "Max supply",
      icon: <TbChartDonut4 size={20} style={{ marginRight: "4px" }} />,
      popupTitle: " Max supply",
      popupContent: "Total quantity of asset issued.",
      value: `${formatNumber(Number(data?.data.maxSupply))}`,
    },
    {
      id: 6,
      title: "VWAP 24Hr",
      icon: <MdOutlineShowChart size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "VWAP 24Hr",
      popupContent: "This number is directly associated with the marketcap",
      value: `$${formatPrice(Number(data?.data.vwap24Hr))}`,
    },
  ];

  return (
    <Box>
      <Box className={styles.cryptoInfoWrapper}>
        {cryptoInfoData.map((cryptoItem: cryptoItemProps) => (
          <Box key={cryptoItem.id}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: theme.palette.grey[500] }}
              >
                {cryptoItem.title}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              component="h5"
              sx={{ color: theme.palette.text.primary }}
            >
              {cryptoItem.value}
            </Typography>
          </Box>
        ))}
      </Box>
      <FullPageBlur isVisible={open} setIsVisible={setOpen} />
      <BottomDrawer open={open} setOpen={setOpen} isSolid>
        <Typography variant="h6" align="center" mb={1}>
          {popupContent.infoTitle}
        </Typography>
        <Typography variant="body1">{popupContent.info}</Typography>
      </BottomDrawer>
    </Box>
  );
};

export default CryptoInfo;
