import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Loading from "../../components/common/loading";
import PinnedInfluencers from "../../components/influencers/influencersList/PinnedInfluencers";
import AllInfluencers from "../../components/influencers/influencersList/AllInfluencers";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  fetchInfluencersList,
  fetchPinnedInfluencers,
} from "../../features/influencers/influencersSlice";
import styles from "./influencers.module.scss";

const Influencers = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const influencersList: any = useSelector(
    (state: RootState) => state.influencers
  );

  const [page, setPage] = useState<number>(
    influencersList?.influencersList && influencersList.influencersList.previous
      ? Number(
          influencersList.influencersList.previous?.match(/page=(\d+)/)
            ? influencersList.influencersList.previous?.match(/page=(\d+)/)[1]
            : 1
        ) + 1
      : 1
  );
  const [selectedFilter, setSelectedFilter] = useState(
    influencersList.influencersFilter
      ? influencersList.influencersFilter
      : "-followers_count"
  );
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    if (coinsList.availableCoins && !influencersList.pinnedInfluencers) {
      dispatch(fetchPinnedInfluencers());
    }
  }, [coinsList.availableCoins, influencersList.pinnedInfluencers]);

  useEffect(() => {
    if (coinsList.availableCoins && !influencersList.influencersList) {
      fetchInfluencersData(1);
    }
  }, [coinsList.availableCoins, influencersList.influencersList]);

  const fetchInfluencersData = (pageNumber: number) => {
    const requestData = {
      ordering: selectedFilter,
      page: pageNumber,
    };
    if (coinsList.availableCoins) {
      dispatch(fetchInfluencersList(requestData));
    }
  };

  const filterInfluencers = () => {
    setPage(1);
    fetchInfluencersData(1);
  };

  const filterHandler = () => {
    setIsFilterOpen(!isFilterOpen);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const influencersPaginationHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    fetchInfluencersData(value);
  };

  return (
    <DashboardLayout title="Coinfident | Influencers">
      {influencersList.pinnedInfluencersLoading &&
      influencersList.influencersListLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            margin: "1rem auto",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <>
          <PinnedInfluencers
            styles={styles}
            influencersList={influencersList}
          />
          <AllInfluencers
            styles={styles}
            influencersList={influencersList}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filterInfluencers={filterInfluencers}
            filterHandler={filterHandler}
            page={page}
            influencersPaginationHandler={influencersPaginationHandler}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default Influencers;
