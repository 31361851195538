import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { Box } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import InfluencerHeader from "../../components/influencer/header";
import CoinMention from "../../components/influencer/coinMention";
import AccountStatistics from "../../components/influencer/accountStatistics";
import AccountStatisticsChart from "../../components/influencer/accountStatisticsChart";
import NewsCard from "../../components/common/newsCard";
import CardTitle from "../../components/common/title/CardTitle";
import Loading from "../../components/common/loading";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { fetchInfluencerData } from "../../features/influencers/influencersSlice";

interface PostType {
  id_str: string;
  created_at: string;
  full_text: string;
  media: string;
  reply_count: number;
  favorite_count: number;
  retweet_count: number;
  user_id_str: string;
}

interface influencerDataProps {
  count: number;
  next: string;
  previous: string;
  results: {
    twitter_user: {
      screen_name: string;
      name: string;
      followers_count?: number;
      friends_count?: number;
      statuses_count?: number;
    };
    tweets: [];
    coin_mention_count: {};
    tweet_count_per_day: [];
  };
}

const Influencer = () => {
  const params = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const [loading, setLoading] = useState(false);
  const [influencerData, setInfluencerData] =
    useState<null | influencerDataProps>(null);
  const [tweets, setTweets] = useState<null | []>(null);

  useEffect(() => {
    if (cryptosState.availableCoins) {
      const requestData = {
        id: params.id,
        page: 1,
      };
      setLoading(true);
      dispatch(fetchInfluencerData(requestData))
        .then((response) => {
          setInfluencerData(response.payload);
          setTweets(response.payload.results.tweets);
          setLoading(false);
        })
        .catch((err) => {});
    }
  }, [cryptosState.availableCoins]);

  const fetchNextPage = () => {
    const requestData = {
      id: params.id,
      page: influencerData?.next?.split("page=")[1],
    };
    dispatch(fetchInfluencerData(requestData)).then((response) => {
      //@ts-ignore
      setTweets(tweets?.concat(response.payload.results.tweets));
      //@ts-ignore
      setInfluencerData({
        ...influencerData,
        next: response.payload.next,
      });
    });
  };

  return (
    <DashboardLayout title="Coinfident | Influencer">
      {loading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Loading />
        </Box>
      ) : (
        influencerData && (
          <>
            <InfluencerHeader data={influencerData.results?.twitter_user} />
            <CoinMention data={influencerData.results?.coin_mention_count} />
            <AccountStatistics data={influencerData} />
            {influencerData.results.tweet_count_per_day.length > 1 && (
              <AccountStatisticsChart
                data={influencerData.results?.tweet_count_per_day}
              />
            )}
            {tweets && tweets.length > 0 && (
              <Box mt={5}>
                <CardTitle small>Posts</CardTitle>
                <Box sx={{ padding: "2.5rem .8rem 0" }}>
                  <InfiniteScroll
                    dataLength={tweets.length}
                    next={fetchNextPage}
                    hasMore={influencerData.next ? true : false}
                    loader={<Loading />}
                  >
                    {tweets?.map((post: PostType) => (
                      <NewsCard
                        key={post.id_str}
                        id={post.user_id_str}
                        screen_name={
                          influencerData.results?.twitter_user.screen_name
                        }
                        media={post.media}
                        name={influencerData.results?.twitter_user.name}
                        date={moment
                          .parseZone(post.created_at)
                          .format("DD MMMM YYYY")}
                        content={post.full_text}
                        reply_count={post.reply_count ? post.reply_count : 0}
                        favorite_count={
                          post.favorite_count ? post.favorite_count : 0
                        }
                        retweet_count={
                          post.retweet_count ? post.retweet_count : 0
                        }
                      />
                    ))}
                  </InfiniteScroll>
                </Box>
              </Box>
            )}
          </>
        )
      )}
    </DashboardLayout>
  );
};

export default Influencer;
