import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@emotion/react";
import { Toaster } from "react-hot-toast";
import { lightTheme } from "./utils/theme/light";
import { darkTheme } from "./utils/theme/dark";
import { RootState } from "./features/store";
import { RoutesWrapper } from "./services/RoutesWrapper";
import { authRoutes, mainRoutes } from "./routers/Routers";
import AppLayout from "./layout/appLayout";
import "./App.css";

interface Route {
  pathName: string;
  element: JSX.Element;
}

function App() {
  const themeMode = useSelector((state: RootState) => state.app.theme);

  return (
    <ThemeProvider theme={themeMode === "light" ? lightTheme : darkTheme}>
      <BrowserRouter>
        <AppLayout>
          <RoutesWrapper>
            <Routes>
              {(authRoutes as Route[]).map((route, index) => (
                <Route
                  path={route.pathName}
                  element={route.element}
                  key={index}
                />
              ))}
              {(mainRoutes as Route[]).map((route, index) => (
                <Route
                  path={route.pathName}
                  element={route.element}
                  key={index}
                />
              ))}
            </Routes>
            <Toaster
              reverseOrder={false}
              position="bottom-center"
              containerStyle={{ fontFamily: "Poppins" }}
            />
          </RoutesWrapper>
        </AppLayout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
