import { Box, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { formatPrice } from "../../../../utils/functions";
import styles from "../../botDetection.module.scss";

const WalletTransaction: React.FC<{ data: string }> = ({ data }) => {
  const theme = useTheme();
  const transactionData = data.split(" ");

  return (
    <Box
      className={styles.transactionCard}
      sx={{
        background: theme.palette.background.paper,
      }}
    >
      <Box
        className={styles.transactionCardTitle}
        sx={{ background: "transparent" }}
      >
        <Box>
          <Typography
            variant="body2"
            component="h2"
            sx={{ color: theme.palette.common.white }}
          >
            {`${
              transactionData[0][0].toUpperCase() + transactionData[0].slice(1)
            }  ${transactionData[1]}`}
          </Typography>
          <Typography
            variant="caption"
            component="h4"
            sx={{
              color:
                transactionData[1] === "in"
                  ? //@ts-ignore
                    theme.palette.common.primaryToGreen
                  : theme.palette.error.main,
            }}
          >
            {transactionData[1] === "in" ? "+" : "-"}
            {formatPrice(Number(transactionData[2]))} {transactionData[3]}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            component="h3"
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            {transactionData[1] === "in" ? "From: " : "To: "}
            {transactionData[5]}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            component="h3"
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey["700"]
                  : theme.palette.common.white,
            }}
          >
            {`${transactionData[8]} ${transactionData[9]}`}
          </Typography>
          <Typography
            variant="body2"
            component="h3"
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey["700"]
                  : theme.palette.common.white,
            }}
          >
            {moment.parseZone(transactionData[11]).format("YYYY-MM-DD ")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WalletTransaction;
