import { Box } from "@mui/material";
import CardTitle from "../../common/title/CardTitle";
import MoversMarquee from "./moversMarquee";
import Loading from "../../common/loading";

interface MoversProps {
  moversState: {
    data: {}[];
    loading: boolean;
  };
}

const Movers: React.FC<MoversProps> = ({ moversState }) => {
  return (
    <Box mt={2}>
      <Box mb={1}>
        <CardTitle
          hasInfo
          infoTitle="Top Gainers/Losers"
          info="The 'Top Gainers/Losers' in the cryptocurrency market are coins that often conclude the day with a higher price compared to their opening, considering their previous closing value in intraday trading."
        >
          Top Gainers/Losers
        </CardTitle>
      </Box>
      {moversState.loading ? (
        <Loading />
      ) : (
        <>
          <MoversMarquee moversData={moversState} type="top_gainers" />
          <MoversMarquee moversData={moversState} type="top_losers" />
        </>
      )}
    </Box>
  );
};

export default Movers;
