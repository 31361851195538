import { Box, useTheme } from "@mui/material";
import styles from "../cryptoSignalHeader.module.scss";

interface TabSelectorProps {
  value: { tab: string };
  handleChange: Function;
}

const TabSelector: React.FC<TabSelectorProps> = ({ value, handleChange }) => {
  const theme = useTheme();

  const tabActiveStyle = {
    activeTab: {
      //@ts-ignore
      color: theme.palette.common.greenToWhite,
      //@ts-ignore
      background: theme.palette.common.whiteToGreen,
    },
  };

  const tabStyle = {
    tab: {
      color: theme.palette.text.primary,
      background: theme.palette.grey["700"],
    },
  };

  return (
    <Box className={styles.tabSelectorWrapper}>
      <Box
        className={styles.tabItem}
        style={value.tab === "" ? tabActiveStyle.activeTab : tabStyle.tab}
        onClick={() => handleChange("")}
      >
        Overall
      </Box>
      <Box
        className={styles.tabItem}
        style={
          value.tab === "influencer" ? tabActiveStyle.activeTab : tabStyle.tab
        }
        onClick={() => handleChange("influencer")}
      >
        Influencers
      </Box>
      <Box
        className={styles.tabItem}
        style={value.tab === "news" ? tabActiveStyle.activeTab : tabStyle.tab}
        onClick={() => handleChange("news")}
      >
        News
      </Box>
    </Box>
  );
};

export default TabSelector;
