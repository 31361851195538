import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import Loading from "../../common/loading";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import {
  fetchInfluencerSignal,
  setNewsPageData,
} from "../../../features/signal/signalSlice";
import CryptoSignalTab from "./cryptoSignalTab";
import CryptoSignalHeader from "./cryptoSignalHeader";
import { calculateGuageValue } from "../../../utils/functions";
import styles from "../crypto.module.scss";

const CryptoSignal: React.FC<{
  coinId: number;
  isAvailable: undefined | {};
}> = ({ coinId, isAvailable }) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const influencerSignal = useSelector((state: RootState) => state.signal);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCommunity, setSelectedCommunity] = useState({
    tab: "",
    coin: coinId,
  });

  const fetchNewsDataHandler = (tab: string, coin: number) => {
    const requestProps = {
      coin: coin,
      page: 1,
      category: tab,
    };

    setLoading(true);
    dispatch(fetchInfluencerSignal(requestProps)).then((response) => {
      setLoading(false);
      dispatch(
        //@ts-ignore
        setNewsPageData({
          count: response.payload.count,
          next: response.payload.next,
          results: response.payload.results,
          tab: tab,
          coin: coin,
        })
      );
    });
  };

  useEffect(() => {
    if (influencerSignal.newsPageData) {
      setSelectedCommunity({
        tab: influencerSignal.newsPageData
          ? influencerSignal.newsPageData.tab === ""
            ? ""
            : influencerSignal.newsPageData.tab
          : "influencer",
        coin: influencerSignal.newsPageData
          ? influencerSignal.newsPageData.coin
          : 3,
      });
    }
  }, [influencerSignal.newsPageData]);

  useEffect(() => {
    if (coinsList.availableCoins)
      fetchNewsDataHandler(selectedCommunity.tab, selectedCommunity.coin);
  }, [coinsList.availableCoins]);

  const fetchNextPage = () => {
    const requestProps = {
      coin: selectedCommunity.coin,
      page: Number(influencerSignal.newsPageData?.next?.split("page=")[1]),
      category: selectedCommunity.tab,
    };
    dispatch(fetchInfluencerSignal(requestProps)).then((response) =>
      dispatch(
        //@ts-ignore
        setNewsPageData({
          count: response.payload.count,
          next: response.payload.next,
          //@ts-ignore
          results: {
            ...influencerSignal.newsPageData?.results,
            relevant_tweets:
              influencerSignal.newsPageData?.results.relevant_tweets.concat(
                response.payload.results.relevant_tweets
              ),
          },
          tab: selectedCommunity.tab,
          coin: selectedCommunity.coin,
        })
      )
    );
  };

  return (
    <Box mt={3}>
      {!influencerSignal.influencerSignal ||
      influencerSignal.influencerSignalLoading ? (
        <Loading />
      ) : coinsList.availableCoins && influencerSignal.newsPageData ? (
        <Box className={styles.coinSignalWrapper}>
          <CryptoSignalHeader
            loading={loading}
            selectedCommunity={selectedCommunity}
            setSelectedCommunity={setSelectedCommunity}
            fetchNewsDataHandler={fetchNewsDataHandler}
          />
          {loading ? (
            <Loading />
          ) : influencerSignal.newsPageData &&
            influencerSignal.newsPageData.results.signal ? (
            <CryptoSignalTab
              guageValue={
                isAvailable !== undefined &&
                Object.keys(influencerSignal.newsPageData.results.signal)
                  .length > 0
                  ? calculateGuageValue(
                      influencerSignal.newsPageData.results.signal[0] ?? 0,
                      influencerSignal.newsPageData.results.signal[1] ?? 0,
                      influencerSignal.newsPageData.results.signal.null ?? 0
                    )
                  : 50
              }
              cryptoSignalData={influencerSignal.newsPageData}
              fetchNextPage={fetchNextPage}
            />
          ) : (
            ""
          )}

          {
            //@ts-ignore
            !coinsList.availableCoins.filter(
              (item: { coin_id: number | string }) =>
                //@ts-ignore
                item.coin_id === influencerSignal.newsPageData.coin
            ).length && (
              <Box
                className={styles.comingSoonWrapper}
                sx={{
                  background:
                    theme.palette.mode === "light"
                      ? "rgba(255,255,255,.8)"
                      : "rgba(0,0,0,.8)",
                }}
              >
                <Typography variant="h5"> Coming Soon </Typography>
              </Box>
            )
          }
        </Box>
      ) : null}
    </Box>
  );
};

export default CryptoSignal;
