import React from "react";
import Select, { components, ControlProps } from "react-select";
import { Grid, Typography, useTheme } from "@mui/material";

interface SelectItem {
  value: string;
  label: string;
}

interface Props {
  selectItems: SelectItem[];
  label: string;
  formik: any;
  name: string;
  helpText?: string;
  hasInlineLabel?: boolean;
  hasInnerLabel?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  titleIcon?: JSX.Element;
  isEmpty?: boolean;
  isMulti?: boolean;
}

const SelectBox: React.FC<Props> = ({
  selectItems,
  label,
  formik,
  name,
  helpText,
  hasInlineLabel = false,
  hasInnerLabel = true,
  disabled,
  icon,
  titleIcon,
  isEmpty,
  isMulti = false,
}) => {
  const theme = useTheme();

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 1000,
    }),
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: isEmpty && "#e6f5ff",
      maxWidth: "100%",
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      color: theme.palette.text.primary,
      fontWeight: 100,
    }),
  };

  const Control = ({ children, ...props }: ControlProps<any, false>) => {
    // @ts-ignore
    const style = {
      cursor: "pointer",
      padding: ".5rem .3rem 0",
      zIndex: 10,
    };

    return (
      <components.Control {...props}>
        <span style={style}>{icon}</span>
        {children}
      </components.Control>
    );
  };

  return (
    <Grid container>
      {hasInlineLabel ? (
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: { xs: ".3rem !important" },
            display: "flex",
            alignItems: "center",
          }}
        >
          {titleIcon ? titleIcon : ""}
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 100,
            }}
          >
            {label}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid item xs={12} className="react-select-container">
        <Select
          // @ts-ignore
          components={{ Control }}
          value={formik.values[name]}
          options={selectItems}
          name={name}
          placeholder={hasInnerLabel ? label : ""}
          onChange={(selectValue: any) =>
            formik.setFieldValue([name], selectValue)
          }
          isDisabled={disabled === true ? true : false}
          styles={customStyles}
          isOptionDisabled={(option: any) => option.disabled}
          onBlur={() => {
            formik.setFieldTouched([name]);
          }}
          // @ts-ignore
          isMulti={isMulti}
          isClearable
        />
        <Typography variant="caption">{helpText}</Typography>
        {formik.touched[name] && formik.errors[name] && (
          <Typography className="formik-error">
            {formik.errors[name]
              ? formik.errors[name]
              : formik.errors[name].value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SelectBox;
