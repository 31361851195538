import { useState } from "react";
import { Slide } from "react-awesome-reveal";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { FaXTwitter } from "react-icons/fa6";
import { RiPushpin2Fill } from "react-icons/ri";
import { IoShareSocialOutline } from "react-icons/io5";
import { PiPushPinSimpleSlashFill } from "react-icons/pi";
import BottomDrawer from "../../common/bottomDrawer";
import Share from "../../common/share";
import { pinInfluencer, removeInfluencerPin } from "../../../api/influencers";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import { fetchPinnedInfluencers } from "../../../features/influencers/influencersSlice";
import styles from "../influencers.module.scss";

interface InfluencerPopoverProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setClickedInfluencerData: React.Dispatch<React.SetStateAction<any | null>>;
  position: string;
  isPinned: boolean;
  influencerData: {
    id_str: string | number;
    screen_name: string;
  };
}

const InfluencerPopover: React.FC<InfluencerPopoverProps> = ({
  isVisible,
  setIsVisible,
  position,
  influencerData,
  isPinned,
  setClickedInfluencerData,
}) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const pinUserHandler = () => {
    setLoading(true);
    pinInfluencer(influencerData.id_str)
      .then((response) => {
        setLoading(false);
        if (response.status === 201) {
          dispatch(fetchPinnedInfluencers());
          setIsVisible(false);
          setClickedInfluencerData(null);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const unpinUserHandler = () => {
    setLoading(true);
    removeInfluencerPin(influencerData.id_str)
      .then((response) => {
        setLoading(false);
        if (response.status === 204) {
          dispatch(fetchPinnedInfluencers());
          setIsVisible(false);
          setClickedInfluencerData(null);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {isVisible && (
        <Box
          className={`${styles.popover} top-zindex`}
          sx={{
            left: position === "left" ? "-100%" : "auto",
            right: position === "right" ? "-100%" : "auto",
          }}
        >
          <Slide direction="down" duration={900}>
            <Button
              disabled={loading ? true : false}
              variant="text"
              size="small"
              startIcon={
                isPinned ? (
                  <PiPushPinSimpleSlashFill size={16} />
                ) : (
                  <RiPushpin2Fill size={16} />
                )
              }
              sx={{
                flexDirection: position === "right" ? "row" : "row-reverse",
                //@ts-ignore
                color: theme.palette.common.whiteToGreen,
              }}
              onClick={isPinned ? unpinUserHandler : pinUserHandler}
            >
              {isPinned ? "Unpin" : "Pin"}
            </Button>
          </Slide>

          <Slide direction="down" duration={1000}>
            <Button
              variant="text"
              size="small"
              startIcon={<FaXTwitter size={14} />}
              sx={{
                flexDirection: position === "right" ? "row" : "row-reverse",
                //@ts-ignore
                color: theme.palette.common.whiteToGreen,
              }}
            >
              <a
                href={`https://twitter.com/${influencerData.screen_name}`}
                target="_blank"
                style={{
                  //@ts-ignore
                  color: theme.palette.common.whiteToGreen,
                  width: "100%",
                  textAlign: position === "right" ? "left" : "right",
                }}
              >
                X (Twitter)
              </a>
            </Button>
          </Slide>

          <Slide direction="down" duration={1100}>
            <Button
              variant="text"
              size="small"
              startIcon={<IoShareSocialOutline size={16} />}
              sx={{
                flexDirection: position === "right" ? "row" : "row-reverse",
                //@ts-ignore
                color: theme.palette.common.whiteToGreen,
              }}
              onClick={() => setOpen(true)}
            >
              Share
            </Button>
          </Slide>
        </Box>
      )}
      <BottomDrawer open={open} setOpen={setOpen}>
        <Box className={styles.shareContainer}>
          <Share
            facebookUrl={`https://twitter.com/${influencerData.screen_name}`}
            telegramUrl={`https://twitter.com/${influencerData.screen_name}`}
            gmailUrl={`https://twitter.com/${influencerData.screen_name}`}
            twitterUrl={`https://twitter.com/${influencerData.screen_name}`}
          />
          <Typography variant="body2" align="center" pt={2}>
            Share this profile with the social media users
          </Typography>
        </Box>
      </BottomDrawer>
    </>
  );
};

export default InfluencerPopover;
