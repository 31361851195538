import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { Box, Container, IconButton, useTheme } from "@mui/material";
import { HiArrowNarrowLeft } from "react-icons/hi";
const { Helmet } = require("react-helmet");

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

const SubPagesLayout: React.FC<LayoutProps> = ({
  children,
  title,
  description,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const backButtonHandler = () => {
    navigate(-1);
  };

  return (
    <>
      {title && (
        <Helmet>
          {title && <title>{title}</title>}
          {description && <meta name="description" content={description} />}
        </Helmet>
      )}
      <Fade>
        <Container
          style={{
            maxWidth: "1100px",
            padding: 0,
            position: "relative",
            zIndex: 2001,
          }}
        >
          <Box
            sx={{
              flex: 1,
              position: "relative",
              padding: "40px 0 10px",
              zIndex: 2001,
            }}
          >
            <IconButton
              aria-label="Back"
              size="small"
              onClick={backButtonHandler}
              style={{
                position: "absolute",
                top: "5px",
                left: "5px",
                zIndex: 200000,
              }}
            >
              <HiArrowNarrowLeft color={theme.palette.text.primary} size={32} />
            </IconButton>
            {children}
          </Box>
        </Container>
      </Fade>
    </>
  );
};

export default SubPagesLayout;
