import { Box, Typography, useTheme } from "@mui/material";
import styles from "../influencer.module.scss";

interface InfluencerHeaderProps {
  data: {
    count: number;
    results: {
      tweets: [];
      twitter_user: {
        followers_count?: number;
        friends_count?: number;
        statuses_count?: number;
      };
    };
  };
}

const AccountStatistics: React.FC<InfluencerHeaderProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <Box mt={3} className={styles.accountStatistics}>
      <Box>
        <Typography
          variant="body2"
          component="h6"
          color={theme.palette.primary.main}
        >
          Posts
        </Typography>
        <Typography
          variant="h5"
          //@ts-ignore
          color={theme.palette.common.primaryToGreen}
        >
          {data.results.twitter_user?.statuses_count?.toLocaleString()}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          component="h6"
          color={theme.palette.primary.main}
        >
          Followers
        </Typography>
        <Typography
          variant="h5"
          //@ts-ignore
          color={theme.palette.common.primaryToGreen}
        >
          {data.results.twitter_user?.followers_count?.toLocaleString()}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          component="h6"
          color={theme.palette.primary.main}
        >
          Following
        </Typography>
        <Typography
          variant="h5"
          //@ts-ignore
          color={theme.palette.common.primaryToGreen}
        >
          {data.results.twitter_user?.friends_count?.toLocaleString()}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountStatistics;
