import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { BiPalette } from "react-icons/bi";
import { HiMiniLanguage } from "react-icons/hi2";
import { MdKeyboardArrowDown } from "react-icons/md";
import SubPagesLayout from "../../layout/subPagesLayout";
import ThemeSwitcher from "../../components/layout/themeSwitcher";
import CardTitle from "../../components/common/title/CardTitle";
import styles from "./settings.module.scss";

interface SettingType {
  id: number;
  disabled: boolean;
  title: string;
  logo: React.ReactNode;
  content: string | React.ReactNode;
}

const Settings = () => {
  const theme = useTheme();

  const settingItems = [
    {
      id: 1,
      disabled: false,
      title: "Appearance",
      logo: <BiPalette size={24} />,
      content: <ThemeSwitcher />,
    },
    {
      id: 2,
      disabled: true,
      title: "Language",
      logo: <HiMiniLanguage size={24} />,
      content: "",
    },
  ];

  return (
    <SubPagesLayout title="Coinfident | Settings">
      <Box className={styles.settingsContent}>
        <CardTitle isCenter>Settings</CardTitle>
        {settingItems.map((setting: SettingType) => (
          <Accordion
            key={setting.id}
            sx={{
              boxShadow: "none",
              background: "transparent",
              "& .MuiCollapse-root": {
                visibility: "visible",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<MdKeyboardArrowDown />}
              aria-controls={`panel${setting.id}-content`}
              id={`panel${setting.id}-header`}
              disabled={setting.disabled ? true : false}
            >
              <Typography
                variant="h6"
                sx={{ color: theme.palette.text.primary }}
              >
                {setting.logo}
                {setting.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{setting.content}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </SubPagesLayout>
  );
};

export default Settings;
