import { Box, Typography, Modal, useTheme } from "@mui/material";

interface DefaultModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: React.ReactNode;
}

const DefaultModal: React.FC<DefaultModalProps> = ({
  open,
  setOpen,
  title,
  content,
}) => {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "400px",
    borderRadius: "20px",
    //@ts-ignore
    bgcolor:
      theme.palette.mode === "light"
        ? theme.palette.grey["400"]
        : theme.palette.grey["800"],
    boxShadow: 24,
    p: 3,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-title"
            variant="body1"
            component="h2"
            color={theme.palette.text.primary}
            sx={{
              paddingBottom: "4px",
              borderBottom: `1px solid
            ${
              //@ts-ignore
              theme.palette.grey.main
            }`,
            }}
          >
            {title}
          </Typography>
          {content}
        </Box>
      </Modal>
    </div>
  );
};

export default DefaultModal;
