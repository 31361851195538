import { setAuthHeader } from "./http";
import { checkAuthToken } from "../api/auth";

export function setAuthToken(token: string, refreshToken: string) {
  if (!token) return removeAuthTokens();

  localStorage.setItem("access-token", token);
  if (refreshToken) localStorage.setItem("refresh-token", refreshToken);

  setAuthHeader(token);
}

export function removeAuthTokens() {
  if (localStorage.getItem("access-token")) {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    //@ts-ignore
    setAuthToken(null);
  }
}

export async function checkAuth() {
  if (!localStorage.getItem("access-token")) return null;

  const token = localStorage.getItem("access-token");
  //@ts-ignore
  setAuthToken(token);

  try {
    //@ts-ignore
    return await checkAuthToken(token);
  } catch (e) {
    return null;
  }
}
