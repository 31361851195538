import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { BiFilterAlt } from "react-icons/bi";
import { RootState } from "../../../../features/store";
import { getCoinImage } from "../../../../utils/functions";
import styles from "../newsHeader.module.scss";

interface CoinsListProps {
  newsType: string;
  twitterFilters: {
    ordering: string;
    selectedCoins: {}[];
  };
  setTwitterFilters: Function;
}

interface CoinType {
  coin_id: number;
  logo: string;
  label: string;
}

const CoinsList: React.FC<CoinsListProps> = ({
  newsType,
  twitterFilters,
  setTwitterFilters,
}) => {
  const theme = useTheme();
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const [selectedCoins, setSelectedCoins] = useState<any>(
    twitterFilters.selectedCoins ? twitterFilters.selectedCoins : []
  );

  const selectCoinHandler = (coin: { logo: string }) => {
    const selectedCoin = coin.logo;

    if (selectedCoins.includes(selectedCoin)) {
      setSelectedCoins((prevDataArray: []) =>
        prevDataArray.filter((logo: string) => logo !== selectedCoin)
      );
    } else {
      setSelectedCoins((prevDataArray: []) => [...prevDataArray, selectedCoin]);
    }

    setTwitterFilters({
      ...twitterFilters,
      selectedCoins: selectedCoins.includes(selectedCoin)
        ? selectedCoins.filter((logo: string) => logo !== selectedCoin)
        : [...selectedCoins, selectedCoin],
    });
  };

  return (
    <Box className={styles.coinsListWrapper}>
      <Typography variant="h6">
        <BiFilterAlt
          size={18}
          color={
            //@ts-ignore
            theme.palette.common.whiteToDarkGreen
          }
        />
      </Typography>
      <Box className={styles.coinsList}>
        {coinsList.availableCoins &&
          //@ts-ignore
          coinsList.availableCoins.map((coin: CoinType) => (
            <Box
              className={styles.coinItem}
              key={coin.coin_id}
              onClick={() => selectCoinHandler(coin)}
              sx={{
                background: selectedCoins.includes(coin.logo)
                  ? theme.palette.primary.light
                  : "transparent",
              }}
            >
              <img src={getCoinImage(coin.coin_id)} />
              <Typography
                variant="caption"
                color={
                  //@ts-ignore
                  theme.palette.common.whiteToDarkGreen
                }
              >
                {coin.label}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default CoinsList;
