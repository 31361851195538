import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { BiSolidSun } from "react-icons/bi";
import { BsFillMoonFill } from "react-icons/bs";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { themeSwitcher } from "../../../features/app/appSlice";
import styles from "../layout.module.scss";

const ThemeSwitcher = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const themeMode = useSelector((state: RootState) => state.app.theme);

  return (
    <Box className={styles.themeSwitcher}>
      <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
        Theme:
      </Typography>
      <Box>
        <BsFillMoonFill
          style={{
            border: `1px solid ${theme.palette.text.primary}`,
            background:
              themeMode === "dark" ? theme.palette.primary.main : "transparent",
          }}
          onClick={() => dispatch(themeSwitcher("dark"))}
        />
        <BiSolidSun
          style={{
            border: `1px solid ${theme.palette.text.primary}`,
            background:
              themeMode === "light"
                ? theme.palette.primary.main
                : "transparent",
          }}
          onClick={() => dispatch(themeSwitcher("light"))}
        />
      </Box>
    </Box>
  );
};

export default ThemeSwitcher;
