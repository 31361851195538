import Marquee from "react-fast-marquee";
import { Box, Typography, useTheme } from "@mui/material";
import styles from "./moversMarquee.module.scss";

interface MoversProps {
  moversData: {
    data: {}[];
    loading: boolean;
  };
  type: string;
}

const MoversMarquee: React.FC<MoversProps> = ({ moversData, type }) => {
  const theme = useTheme();

  return (
    <Box className={styles.moversMarquee}>
      <Marquee
        autoFill={true}
        pauseOnHover={true}
        speed={30}
        style={{
          marginTop: ".6rem",
          paddingTop: ".2rem",
          borderRadius: "15px",
          background:
            type === "top_gainers"
              ? "linear-gradient(180deg,rgba(117, 214, 161, .8) 0%,rgba(255, 255, 255, 0) 100%)"
              : "linear-gradient(180deg,rgba(250, 99, 122, .8) 0%,rgba(255, 255, 255, 0) 100%)",
        }}
      >
        {
          //@ts-ignore
          moversData.data[type].map((moverItem) => (
            <Box className={styles.moverItemWrapper} key={moverItem.name}>
              <Box
                className={styles.moverItem}
                //@ts-ignore
                style={{ background: theme.palette.common.solidCard }}
              >
                <img src={moverItem.img} alt={moverItem.name} />
                <Box className={styles.moverItemContent}>
                  <Typography variant="body1" component="h2">
                    {moverItem.name}
                  </Typography>
                  <Box>
                    <Typography variant="h6" component="h4">
                      {moverItem.price}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        color:
                          type === "top_gainers"
                            ? //@ts-ignore
                              theme.palette.common.greenText
                            : "#FA637A",
                      }}
                    >
                      {type === "top_gainers" ? "+" : "-"}
                      {moverItem.percentage}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        }
      </Marquee>
    </Box>
  );
};

export default MoversMarquee;
