import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Logo from "../../../components/common/logo";
import Input from "../../../components/form/Input";
import AuthTitle from "../../../components/common/title/AuthTitle";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import { resendVerificationEmail } from "../../../api/auth";
import styles from "../auth.module.scss";

interface ResendVerificationEmailState {
  email: string;
}

const ResendVerificationEmail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const hasToken = localStorage.getItem("access-token");

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] =
    useState<ResendVerificationEmailState>({
      email: "",
    });

  const getSubmitHandler =
    () => async (values: ResendVerificationEmailState) => {
      setLoading(true);

      const response = await resendVerificationEmail(values);

      if (response) {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Verification email sent");
          navigate("/auth/sign-up", { state: "verificationEmailSent" });
        } else {
          toast.error("User is not registered with this email");
        }
      }
    };

  useEffect(() => {
    if (hasToken) {
      navigate("/");
    }
  }, []);

  return (
    <Box className={styles.loginWrapper}>
      <Logo isAuth />
      <Fade>
        <AuthTitle margin="80px 0 120px">Resend Verification Email</AuthTitle>

        <Formik
          initialValues={initialValues}
          validateOnMount={true}
          validationSchema={useCreateSchema(["email"])}
          enableReinitialize={true}
          onSubmit={getSubmitHandler()}
        >
          {(formik) => (
            <Form id="resend_verification_email_form">
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={7}>
                  <Input
                    name="email"
                    formik={formik}
                    label="Email"
                    hasInlineLabel={true}
                    hasInnerLabel={false}
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <PrimaryButton
                    text="Send Email"
                    disabled={!formik.isValid}
                    loading={loading}
                    isFullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box className={styles.linkWrapper}>
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      Email is already verified?
                    </Typography>
                    <Link
                      to="/auth/login"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Login
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Fade>
    </Box>
  );
};

export default ResendVerificationEmail;
