import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { LuInfo } from "react-icons/lu";

interface TableTitleProps {
  children: string;
  isCenter?: boolean;
  hasInfo?: boolean;
  info?: string;
  infoTitle?: string;
  hasMargin?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setItemInfo?: any;
}

const TableTitle: React.FC<TableTitleProps> = ({
  children,
  isCenter,
  hasInfo,
  info,
  infoTitle,
  hasMargin,
  open,
  setOpen,
  setItemInfo,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent:
          hasInfo && isCenter ? "center" : hasInfo ? "space-between" : "center",
        alignItems: "center",
        marginBottom: hasMargin ? ".9rem" : 0,
      }}
    >
      <Typography
        component="h1"
        sx={{
          //@ts-ignore
          color: theme.palette.common.whiteToGreen,
          fontWeight: 500,
          fontSize: "13px",
          lineHeight: 1,
          marginRight: hasInfo ? ".2rem" : 0,
        }}
        align={isCenter ? "center" : "left"}
      >
        {children}
      </Typography>
      {hasInfo && infoTitle ? (
        <Box sx={{ position: "static", zIndex: 10 }}>
          <IconButton
            aria-label="Popup"
            size="small"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              setOpen(!open);
              setItemInfo({
                infoTitle,
                info,
              });
            }}
          >
            <LuInfo
              size={15}
              color={
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500]
              }
            />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default TableTitle;
