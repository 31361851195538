import { Slide } from "react-awesome-reveal";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { BiSort } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { PiFileTextBold } from "react-icons/pi";
import { RiUserSharedLine } from "react-icons/ri";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import { setInfluencersFilter } from "../../../features/influencers/influencersSlice";
import styles from "../influencers.module.scss";

interface InfluencersFilterProps {
  isFilterOpen: boolean;
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  filterInfluencers: Function;
}

const InfluencersFilter: React.FC<InfluencersFilterProps> = ({
  isFilterOpen,
  setIsFilterOpen,
  selectedFilter,
  setSelectedFilter,
  filterInfluencers,
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <>
      <Box
        className={`${isFilterOpen ? "top-zindex" : ""} ${
          styles.filterContainer
        }`}
      >
        <Slide direction="up">
          <Box className={styles.filtersHeader}>
            <BiSort
              size={22}
              //@ts-ignore
              color={theme.palette.common.whiteToGreen}
            />
            <Typography
              variant="body1"
              component="h6"
              //@ts-ignore
              color={theme.palette.common.whiteToGreen}
            >
              Sort By
            </Typography>
            <MdClose
              size={22}
              //@ts-ignore
              color={theme.palette.common.whiteToGreen}
              onClick={() => setIsFilterOpen(false)}
            />
          </Box>

          <Box className={styles.filterItems}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="statuses_count"
                name="radio-buttons-group"
                value={selectedFilter}
              >
                <FormControlLabel
                  value="-followers_count"
                  control={
                    <Radio
                      onChange={() => {
                        dispatch(setInfluencersFilter("-followers_count"));
                        setSelectedFilter("-followers_count");
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FaUsers
                        size={22}
                        style={{
                          marginRight: ".4rem",
                          color:
                            //@ts-ignore
                            theme.palette.common.whiteToGreen,
                        }}
                      />
                      <Typography
                        sx={{
                          color:
                            //@ts-ignore
                            theme.palette.common.whiteToGreen,
                        }}
                      >
                        Followers count
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="-friends_count"
                  control={
                    <Radio
                      onChange={() => {
                        dispatch(setInfluencersFilter("-friends_count"));
                        setSelectedFilter("-friends_count");
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <RiUserSharedLine
                        size={22}
                        style={{
                          marginRight: ".4rem",
                          color:
                            //@ts-ignore
                            theme.palette.common.whiteToGreen,
                        }}
                      />
                      <Typography
                        sx={{
                          color:
                            //@ts-ignore
                            theme.palette.common.whiteToGreen,
                        }}
                      >
                        Following count
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="-statuses_count"
                  control={
                    <Radio
                      onChange={() => {
                        dispatch(setInfluencersFilter("-statuses_count"));
                        setSelectedFilter("-statuses_count");
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PiFileTextBold
                        size={22}
                        style={{
                          marginRight: ".4rem",
                          color:
                            //@ts-ignore
                            theme.palette.common.whiteToGreen,
                        }}
                      />
                      <Typography
                        sx={{
                          color:
                            //@ts-ignore
                            theme.palette.common.whiteToGreen,
                        }}
                      >
                        Posts count
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            onClick={() => {
              setIsFilterOpen(false);
              filterInfluencers(selectedFilter);
            }}
            sx={{ maxWidth: "600px" }}
          >
            <PrimaryButton text="Filter" isFullWidth size="small" />
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default InfluencersFilter;
