import { Typography, useTheme } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import CardTitle from "../../components/common/title/CardTitle";

const NotFound = () => {
  const theme = useTheme();

  return (
    <DashboardLayout title="Coinfident | 404">
      <Typography
        variant="h2"
        align="center"
        mb={1}
        mt={20}
        sx={{
          //@ts-ignore
          color: theme.palette.common.whiteToGreen,
        }}
      >
        404
      </Typography>
      <CardTitle isCenter>Not Found</CardTitle>
    </DashboardLayout>
  );
};

export default NotFound;
