export const calculatePatternsPosition = (urlAddress: string) => {
  let targetPosition = {
    pattern_1: ["82%", "-18%"],
    pattern_2: ["20px", "-50px"],
  };

  switch (urlAddress) {
    case "/":
      targetPosition = {
        pattern_1: ["82%", "-18%"],
        pattern_2: ["20px", "-50px"],
      };
      break;
    case "/news":
      targetPosition = {
        pattern_1: ["79%", "-18%"],
        pattern_2: ["50px", "-90px"],
      };
      break;
    case "/influencers":
      targetPosition = {
        pattern_1: ["74%", "-18%"],
        pattern_2: ["0px", "-60px"],
      };
      break;

    case "/share-of-voice":
      targetPosition = {
        pattern_1: ["75%", "-20%"],
        pattern_2: ["10px", "-120px"],
      };
      break;
    case "/auth/login":
      targetPosition = {
        pattern_1: ["75%", "-80px"],
        pattern_2: ["88px", "-100px"],
      };
      break;
    case "/auth/sign-up":
      targetPosition = {
        pattern_1: ["72px", "-40px"],
        pattern_2: ["56%", "-15%"],
      };
      break;
    case "/auth/resend-verification-email":
      targetPosition = {
        pattern_1: ["85px", "-30px"],
        pattern_2: ["85%", "-13%"],
      };
      break;
    default:
      targetPosition = {
        pattern_1: ["40%", "-100px"],
        pattern_2: ["10px", "-50px"],
      };
      break;
  }

  return targetPosition;
};

export const calculateInsidePatternsPosition = (urlAddress: string) => {
  let targetPosition = {
    pattern_3: ["60%", "130%", "0"],
    pattern_4: ["-10%", "100%"],
    pattern_5: ["-10%", "100%"],
    pattern_6: ["100%", "-30%", "90"],
  };

  switch (urlAddress) {
    case "/account":
      targetPosition = {
        pattern_3: ["62%", "85%", "45"],
        pattern_4: ["73%", "60%"],
        pattern_5: ["23%", "80%"],
        pattern_6: ["60%", "84%", "15"],
      };
      break;
    case "/account/settings":
      targetPosition = {
        pattern_3: ["89%", "5%", "0"],
        pattern_4: ["70%", "5%"],
        pattern_5: ["10%", "10%"],
        pattern_6: ["96%", "-9%", "360"],
      };
      break;
    case "/account/management":
      targetPosition = {
        pattern_3: ["61%", "4%", "0"],
        pattern_4: ["65%", "35%"],
        pattern_5: ["20%", "1%"],
        pattern_6: ["56%", "-11%", "220"],
      };
      break;
    case "/account/subscription":
      targetPosition = {
        pattern_3: ["2%", "-4%", "-8"],
        pattern_4: ["70%", "95%"],
        pattern_5: ["6%", "8%"],
        pattern_6: ["98%", "-9%", "360"],
      };
      break;
    case "/account/profile":
      targetPosition = {
        pattern_3: ["79%", "85%", "0"],
        pattern_4: ["40%", "95%"],
        pattern_5: ["16%", "3%"],
        pattern_6: ["70%", "80%", "210"],
      };
      break;

    case "/account/FAQ":
      targetPosition = {
        pattern_3: ["75%", "88%", "-130"],
        pattern_4: ["97%", "88%"],
        pattern_5: ["18%", "8%"],
        pattern_6: ["30%", "78%", "380"],
      };
      break;

    case "/account/terms-and-conditions":
      targetPosition = {
        pattern_3: ["75%", "85%", "-130"],
        pattern_4: ["94%", "90%"],
        pattern_5: ["1%", "5%"],
        pattern_6: ["22%", "80%", "300"],
      };
      break;

    default:
      targetPosition = {
        pattern_3: ["60%", "130%", "0"],
        pattern_4: ["-10%", "100%"],
        pattern_5: ["-10%", "100%"],
        pattern_6: ["100%", "-30%", "90"],
      };
      break;
  }

  return targetPosition;
};
