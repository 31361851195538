import http from "../services/http";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchCryptoTweets = (coinId: number) => {
  const url = `${BASE_URL}/crypto/get-tweet-count/?coin_id=${coinId}`;
  const request = http
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });

  return request;
};
