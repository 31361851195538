import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface InitialState {
  data: null | {
    access: string;
    refresh: string;
    user: {
      first_name: string;
      last_name: string;
      phone_number: string | number;
      email: string;
    };
  };
  isAuthenticated: boolean | null;
  loading: boolean;
  error: null | string;
}
const initialState: InitialState = {
  data: null,
  isAuthenticated: false,
  loading: false,
  error: null,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<string>) => {
      localStorage.setItem(
        "access-token",
        //@ts-ignore
        action.payload.access
      );
      localStorage.setItem(
        "refresh-token",
        //@ts-ignore
        action.payload.refresh
      );
      //@ts-ignore
      state.data = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setUserData, setIsAuthenticated } = accountSlice.actions;
export default accountSlice.reducer;
