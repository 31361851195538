import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import CardTitle from "../../components/common/title/CardTitle";
import Input from "../../components/form/Input";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import Transactions from "../../components/botDetection/transactions";
import Guage from "../../components/guage";
import SelectBox from "../../components/form/SelectBox";
import { useCreateSchema } from "../../utils/useCreateSchema";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  fetchBotDetection,
  fetchBotNetwork,
  setWalletAddress,
  setWalletNetwork,
} from "../../features/wallet/walletSlice";

interface BotDetectionState {
  wallet_address: string;
  network: {
    value: string;
    label: string;
  };
}

const BotDetection = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const walletState = useSelector((state: RootState) => state.wallet);
  const cryptosState = useSelector((state: RootState) => state.cryptos);

  const [initialValues, setInitialValues] = useState<BotDetectionState>({
    wallet_address: walletState.wallet_address
      ? walletState.wallet_address
      : "",
    network: walletState.wallet_network
      ? { label: walletState.wallet_network, value: walletState.wallet_network }
      : { label: "", value: "" },
  });

  const submitUserDataHandler =
    () => async (values: BotDetectionState, formik: any) => {
      const requestData = {
        wallet_address: values.wallet_address,
      };

      //@ts-ignore
      if (values.network) {
        //@ts-ignore
        requestData.network = values.network.value;
      }

      dispatch(setWalletAddress(values.wallet_address));

      //@ts-ignore
      if (values.network) {
        //@ts-ignore
        dispatch(setWalletNetwork(values.network.value));
      }

      dispatch(fetchBotDetection(requestData)).then((response) => {
        if (Number(response.payload.GPT_result)) {
          document.documentElement.scrollTo(0, 450);
        }
      });
    };

  useEffect(() => {
    if (cryptosState.availableCoins && !walletState.botNetwork) {
      dispatch(fetchBotNetwork());
    }
  }, [cryptosState.availableCoins]);

  const noData = () => {
    return (
      <Box mt={10}>
        <Typography
          variant="h6"
          align="center"
          color={theme.palette.text.primary}
        >
          No data found for this wallet
        </Typography>
      </Box>
    );
  };

  return (
    <DashboardLayout title="Coinfident | Bot detection">
      <Box mt={3}>
        <CardTitle isCenter>Bot detection</CardTitle>
        <Box mt={5}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={4} xs={12}>
              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                enableReinitialize={true}
                validationSchema={useCreateSchema(["wallet_address"])}
                onSubmit={submitUserDataHandler()}
              >
                {(formik) => (
                  <Form id="bot_detection_form">
                    <Grid container spacing={6} justifyContent="center">
                      <Grid item xs={12}>
                        <Input
                          name="wallet_address"
                          formik={formik}
                          label="Wallet address:"
                          hasInlineLabel={true}
                          hasInnerLabel={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectBox
                          selectItems={
                            walletState.botNetwork
                              ? walletState.botNetwork.map((network) => ({
                                  value: network,
                                  label: network,
                                }))
                              : []
                          }
                          disabled={walletState.botNetworkLoading}
                          name="network"
                          formik={formik}
                          label="Network:"
                          hasInlineLabel={true}
                          hasInnerLabel={false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PrimaryButton
                          text="Check"
                          disabled={formik.isValid ? false : true}
                          isFullWidth
                          loading={walletState.botDetectionLoading}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {walletState.botDetectionLoading
        ? ""
        : walletState.error
        ? noData()
        : walletState.botDetection && (
            <>
              {walletState.botDetection.wallet_history.length < 1 && noData()}
              {walletState.botDetection.wallet_history.length > 0 && (
                <Transactions data={walletState.botDetection?.wallet_history} />
              )}
              {Number(walletState.botDetection.GPT_result) > -1 && (
                <Fade triggerOnce>
                  <Box
                    className="default-card-style"
                    sx={{ background: theme.palette.background.paper }}
                  >
                    <Typography align="center">
                      Is this wallet a bot?
                    </Typography>
                    <Guage
                      value={Number(
                        (
                          Number(walletState.botDetection.GPT_result) * 100
                        ).toFixed(1)
                      )}
                      isBotChecker
                    />
                  </Box>
                </Fade>
              )}
            </>
          )}
    </DashboardLayout>
  );
};

export default BotDetection;
