import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fade, Slide } from "react-awesome-reveal";
import { Box, Typography, useTheme } from "@mui/material";
import { FaUser } from "react-icons/fa";
import { BiDollar } from "react-icons/bi";
import { LuScrollText } from "react-icons/lu";
import { RiSettings3Fill } from "react-icons/ri";
import { AiOutlineMessage } from "react-icons/ai";
import { MdOutlineAppShortcut } from "react-icons/md";
import SubPagesLayout from "../../layout/subPagesLayout";
import SecondaryButton from "../../components/common/buttons/SecondaryButton";
import CardTitle from "../../components/common/title/CardTitle";
import AccountHeader from "../../components/auth/accountHeader";
import { RootState } from "../../features/store";
import { logout } from "../../api/auth";
import styles from "./account.module.scss";

const Account = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userInfo: any = useSelector((state: RootState) => state.account);

  const [loading, setLoading] = useState<boolean>(false);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const [userFullname, setUserFullname] = useState<string | null>(null);

  useEffect(() => {
    setUserFullname(
      `${userInfo.data?.user.first_name ?? ""} ${
        userInfo.data?.user.last_name ?? ""
      }`
    );
  }, [userInfo]);

  const signoutHandler = async () => {
    setLoading(true);
    const response = await logout();
    if (response.status === 200) {
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");
      setLoading(false);
      navigate("/auth");
    }
  };

  const profileItems = [
    {
      id: 1,
      title: "Profile",
      icon: (
        <FaUser
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 900,
      link: "/account/profile",
    },
    // {
    //   id: 2,
    //   title: "Subscription",
    //   icon: (
    //     <BiDollar
    //       size={24}
    //       //@ts-ignore
    //       color={theme.palette.common.icon}
    //     />
    //   ),
    //   duration: 1000,
    //   link: "/account/subscription",
    // },
    {
      id: 2,
      title: "Settings",
      icon: (
        <RiSettings3Fill
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 1000,
      link: "/account/settings",
    },
    {
      id: 3,
      title: "Account management",
      icon: (
        <MdOutlineAppShortcut
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 1100,
      link: "/account/management",
    },
    {
      id: 4,
      title: "Terms & Conditions",
      icon: (
        <LuScrollText
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 1200,
      link: "/account/terms-and-conditions",
    },
    {
      id: 5,
      title: "FAQ",
      icon: (
        <AiOutlineMessage
          size={24}
          //@ts-ignore
          color={theme.palette.common.icon}
        />
      ),
      duration: 1300,
      link: "/account/FAQ",
    },
  ];

  return (
    <SubPagesLayout title="Coinfident | Account">
      <Fade cascade damping={0.3}>
        <Box className={styles.accountContent}>
          <Box className={styles.accountProfile}>
            <CardTitle isCenter>Account</CardTitle>
            <AccountHeader userFullname={userFullname} userInfo={userInfo} />
          </Box>
          {hasSubscription && (
            <Box
              className={styles.accountPlan}
              sx={{
                borderBottom: `1px solid ${
                  theme.palette.mode === "light"
                    ? //@ts-ignore
                      theme.palette.grey.main
                    : //@ts-ignore
                      theme.palette.grey.dark
                }`,
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? //@ts-ignore
                          theme.palette.grey.dark
                        : //@ts-ignore
                          theme.palette.grey["700"],
                  }}
                >
                  Your Plan
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    //@ts-ignore
                    color: theme.palette.common.greenText,
                  }}
                >
                  Yearly
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? //@ts-ignore
                          theme.palette.grey.dark
                        : //@ts-ignore
                          theme.palette.grey["700"],
                  }}
                >
                  Renews
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      theme.palette.mode === "light"
                        ? //@ts-ignore
                          theme.palette.grey.dark
                        : //@ts-ignore
                          theme.palette.grey["400"],
                  }}
                >
                  Mar 8, 2025
                </Typography>
              </Box>
            </Box>
          )}
          <ul style={{ marginTop: hasSubscription ? 0 : "3rem" }}>
            {profileItems.map((ProfileItem) => (
              <li key={ProfileItem.id}>
                <Slide direction="down" duration={ProfileItem.duration}>
                  <Link
                    to={ProfileItem.link}
                    color={theme.palette.text.primary}
                  >
                    {ProfileItem.icon}
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      {ProfileItem.title}
                    </Typography>
                  </Link>
                </Slide>
              </li>
            ))}
          </ul>
        </Box>
      </Fade>
      <SecondaryButton
        text="Sign Out"
        clickHandler={signoutHandler}
        loading={loading}
      />
    </SubPagesLayout>
  );
};

export default Account;
