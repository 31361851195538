import { Box, useTheme } from "@mui/material";
import loading from "../../../assets/images/icons/loading.png";
import styles from "./loading.module.scss";

const Loading: React.FC<{ hasBackground?: boolean }> = ({ hasBackground }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: hasBackground
          ? theme.palette.background.paper
          : "transparent",
      }}
      className={hasBackground ? "default-card-style" : ""}
    >
      <Box className={styles.loading}>
        <img src={loading} alt="Loading" />
      </Box>
    </Box>
  );
};

export default Loading;
