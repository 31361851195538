import { Box, Typography, useTheme } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import styles from "./guage.module.scss";

const Guage: React.FC<{
  value: number;
  hasBackground?: boolean;
  isEmpty?: boolean;
  isBotChecker?: boolean;
}> = ({ value, hasBackground, isEmpty, isBotChecker }) => {
  const theme = useTheme();

  return (
    <Box className={styles.sentimentGaugeWrapper}>
      {isBotChecker ? (
        <>
          <Typography
            className={styles.sentimentBotText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
            }}
          >
            Bot
          </Typography>
          <Typography
            className={styles.sentimentNotBotText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
            }}
          >
            Not Bot
          </Typography>
        </>
      ) : (
        <>
          <Typography
            className={styles.sentimentNotBuyText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
            }}
          >
            Not buy
          </Typography>
          <Typography
            className={styles.sentimentBuyText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
            }}
          >
            Buy
          </Typography>
        </>
      )}
      <Typography
        className={styles.sentimentValue}
        sx={{
          color: hasBackground
            ? //@ts-ignore
              theme.palette.common.white
            : theme.palette.text.primary,
        }}
      >
        {isEmpty ? "No data" : `${value}%`}
      </Typography>
      <GaugeComponent
        type="semicircle"
        value={value ?? 0}
        arc={{
          colorArray: isEmpty
            ? [theme.palette.grey["700"]]
            : isBotChecker
            ? ["#259659", "#CDD931", "#FA637A"]
            : ["#FA637A", "#CDD931", "#259659"],
          padding: 0,
          width: 0.2,
          gradient: true,
        }}
        pointer={{
          elastic: true,
          length: isEmpty ? 0 : 0.65,
          width: isEmpty ? 0 : 10,
          //@ts-ignore
          color: hasBackground
            ? //@ts-ignore
              theme.palette.common.white
            : theme.palette.text.primary,
        }}
        labels={{
          valueLabel: {
            hide: true,
          },
          tickLabels: {
            hideMinMax: true,
            defaultTickLineConfig: {
              char: "",
            },
          },
        }}
      />
    </Box>
  );
};

export default Guage;
