import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import verificationIcon from "../../../assets/images/icons/verification-icon.png";
import Logo from "../../../components/common/logo";
import Input from "../../../components/form/Input";
import AuthTitle from "../../../components/common/title/AuthTitle";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import { resetPassword } from "../../../api/auth";
import styles from "../auth.module.scss";

interface ResetPasswordState {
  email: string;
}

const ResetPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const hasToken = localStorage.getItem("access-token");
  const validationScheme = useCreateSchema(["email"]);

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<ResetPasswordState>({
    email: "",
  });
  const [resetPasswordAlert, setResetPasswordAlert] = useState(false);

  const getSubmitHandler = () => async (values: ResetPasswordState) => {
    setLoading(true);

    const response = await resetPassword(values);

    if (response) {
      setLoading(false);
      if (response.status === 200) {
        toast.success("Password sent to your email");
        setResetPasswordAlert(true);
      } else {
        toast.error("User is not registered with this email");
      }
    }
  };

  useEffect(() => {
    if (hasToken) {
      navigate("/");
    }
  }, []);

  return (
    <Box className={styles.loginWrapper}>
      <Logo isAuth />
      <Fade>
        {resetPasswordAlert ? (
          <Box className={styles.verificationMessage}>
            <img src={verificationIcon} alt="verification-icon" />
            <Typography
              variant="body1"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              Password sent to your email. <br /> Please check your inbox.
            </Typography>
          </Box>
        ) : (
          <>
            <AuthTitle margin="80px 0 120px">Reset password</AuthTitle>

            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              validationSchema={validationScheme}
              enableReinitialize={true}
              onSubmit={getSubmitHandler()}
            >
              {(formik) => (
                <Form id="reset_password_form">
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={12} sm={7}>
                      <Input
                        name="email"
                        formik={formik}
                        label="Email"
                        hasInlineLabel={true}
                        hasInnerLabel={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <PrimaryButton
                        text="Reset password"
                        disabled={!formik.isValid}
                        loading={loading}
                        isFullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Box className={styles.linkWrapper}>
                        <Typography
                          variant="body2"
                          align="left"
                          sx={{
                            color: theme.palette.text.primary,
                          }}
                        >
                          Already have the password?
                        </Typography>
                        <Link
                          to="/auth/login"
                          style={{ color: theme.palette.primary.main }}
                        >
                          Login
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Fade>
    </Box>
  );
};

export default ResetPassword;
