import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const SmallAreaChart: React.FC<{
  data: { x: string | number; y: number }[];
  name: string;
  color: string;
}> = ({ data, name, color }) => {
  const [state, setState] = useState<{
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    options: object;
  }>({
    series: [],
    options: {},
  });

  useEffect(() => {
    setState({
      series: [
        {
          name,
          data,
        },
      ],
      options: {
        chart: {
          type: "area",
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        //@ts-ignore
        colors: [color],
        grid: { show: false },
        dataLabels: { enabled: false },
        legend: { show: false },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        // xaxis: {
        //   labels: { show: false },
        //   axisBorder: { show: false },
        //   axisTicks: { show: false },
        // },
        yaxis: {
          type: "numeric",
          labels: { show: false },
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0,
            opacityFrom: 0.7,
            opacityTo: 0.2,
            stops: [0],
          },
        },
        tooltip: { enabled: false },
      },
    });
  }, [data]);

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="area"
      height="90px"
    />
  );
};

export default SmallAreaChart;
